import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Switch,
  styled,
  Tab,
  Tabs,
  Checkbox,
  Stack,
  Menu,
  Button,
} from "@mui/material";
import RolesSites from "./RolesSites";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  registerAdminNew,
  registerUser,
  getSponsorBasedStudy,
  getUserById,
  editUser,
  getEnrolledStudiesDataService,
  saveSponsorsAndGetStudiesService,
  getSiteAndRoleDataService,
  updateUserService,
  updateStudyAndSiteDataService,
} from "../../../services/users";
import { getAllSponsors, getSponsorImage } from "../../../services/sponsors";
import { getStudyById } from "../../../services/studies";
import { getStudyRoles } from "../../../services/study_roles";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/style.css";
import { Container, Row, Col } from "react-bootstrap";
import "./User.css";
import DialogBox from "../../../components/DialogBox";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import { useCallback } from "react";
import useUserDetails from "../../../hooks/Api/useUserDetails";
import {
  validationSchemaAdmin,
  validationSchemaSponsor,
  validationSchemaStudy,
  validationSchemaUser,
  AntSwitch,
  label,
  BootstrapTooltip,
  TabPanel,
  a11yProps,
  initialStaticStudyRoles,
  initialStudySites,
  allStudiesRemoved,
  noStudyFound,
  notifyAdmin,
  notifyPhoneNumber,
  notifyStudy,
  notifySponsor,
  notifyUser,
  notifyUserNumber,
  reqFailed,
  studyRemoved,
  notifyFirstName,
  notifyEmail,
  notifyEmailVerify,
  checkBoxOne,
} from "./utils";
import SponsorContext from "../../../context/sponsor/SponsorContext";
import axios from "axios";
import { dataServer } from "../../../services/axios.config";
import { rowStyles } from "../StudySetup/Visits/Questionnaires/EditVisitQuestionnaire";
import { requestFailed } from "../StudySetup/Visits/CreateVisit";

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let uniqueIds = [];
  let uniqueIds2 = [];

  const [id, setId] = useState(location.state?.id || null);

  const phone = location && location.state ? location.state.phone : "123456789";
  const userRole = location && location.state ? location.state.role : "";
  const userFirstName =
    location && location.state ? location.state.firstName : "";
  const userMiddleName =
    location && location.state ? location.state.middleName : "";
  const userLastName =
    location && location.state ? location.state.lastName : "";

  // var userStatus = location.state.status;

  const [value, setValue] = React.useState(0);
  const [showRowSite, setShowRowSite] = React.useState("");
  const [load, setLoad] = React.useState(true);
  const [sponsorsData, setSponsorsData] = React.useState([]);
  const [newSponsor, setNewSponsor] = React.useState([]);
  const [sponsorChecked, setSponsorChecked] = React.useState(() =>
    newSponsor.map((i) => false)
  );
  const [checkedSponsorId, setCheckedSponsorId] = React.useState([]);

  const [checkStudy, setCheckStudy] = React.useState([]);
  const [enrolledStudy, setEnrolledStudy] = React.useState([]);

  const [firstName, setFirstName] = React.useState("");
  const [middleName, setMiddleName] = React.useState();
  const [email, setEmail] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [staticPass, setStaticPass] = React.useState("");
  const [isStaticChecked, setIsStaticChecked] = React.useState(false);
  const [notifyNext, setNotifyNext] = React.useState(false);
  const [isAdminChecked, setIsAdminChecked] = React.useState(
    userRole === "Admin" ? true : false
  );
  const [isActive, setIsActive] = React.useState(false);

  const [adminFirstName, setAdminFirstName] = React.useState("");
  const [adminMiddleName, setAdminMiddleName] = React.useState("");
  const [adminEmail, setAdminEmail] = React.useState("");
  const [adminLastName, setAdminLastName] = React.useState("");
  const [testPhone, setTestPhone] = React.useState(phone);

  const [testPhoneUser, setTestPhoneUser] = React.useState(phone);

  const [studyRoles, setStudyRoles] = React.useState([]);
  const [roleUserId, setRoleUserId] = React.useState("");
  const [roleStudyId, setRoleStudyId] = React.useState("");
  const [roleId, setRoleId] = React.useState("");

  const [operationDone, setOperationDone] = React.useState(false);

  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [showRolesActive, setShowRolesActive] = React.useState(false);
  const [activeSponsor, setActiveSponsor] = React.useState("");
  const [activeStudy, setActiveStudy] = React.useState("");
  const [activeRole, setActiveRole] = React.useState("");
  const [activeSite, setActiveSite] = React.useState("");

  const [activeArrow, setActiveArrow] = React.useState([]);

  const [callStudyImageApi, setCallStudyImageApi] = React.useState(false);
  const [selectedEnrolledStudies, setSelectedEnrolledStudies] = useState(null);
  const [isSponsorChange, setIsSelectedSponsorChange] = useState(false);

  const open1 = Boolean(anchorEl1);

  // const [staticStudyRoles, setStaticStudyRoles] = React.useState(
  //   initialStaticStudyRoles
  // );
  const [staticStudyRoles, setStaticStudyRoles] = React.useState([]);
  const [studySites, setStudySites] = React.useState(initialStudySites);
  const [activeArrStudies, setActiveArrStudies] = React.useState([]);
  const [activeArrSites, setActiveArrSites] = React.useState([]);
  const [studyRoleSitesObject, setStudyRoleSitesObject] = React.useState([]);
  const [activeStudyRole, setActiveStudyRole] = React.useState(null);

  const [studyRoleData, setStudyRoleData] = useState([]);
  const [siteRoleData, setSiteRoleData] = useState([]);
  const [selectedStudyRoles, setSelectedStudyRoles] = useState([]);
  const [selectedSiteRoles, setSelectedSiteRoles] = useState([]);

  const rows = staticStudyRoles.map((row) => ({
    id: row.id,
    name: row.shortName,
    concurrencyStamp: row.isBlinded,
    description: row.description,
  }));

  const rows2 = studySites.map((row) => ({
    id: row.id,
    name: row.name,
    editId: row.editId,
  }));

  // const { sponsorsData, setLoading } =
  //   useContext(SponsorContext);

  const getAllSponsors = (
    pageNumber,
    pageSize,
    sortingType,
    sortingField,
    debouncedSearchString
  ) => {
    return new Promise((resolve, reject) => {
      dataServer
        .get(
          pageNumber && pageSize
            ? `app/Sponsor/all?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"Name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
            : "app/Sponsor/all"
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };

  const fetchSponsors = async () => {
    try {
      const res = await getAllSponsors();
      let activeSponsors = res?.result.filter((item) => item.isActive === true);
      setSponsorsData(activeSponsors);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const getImage = async () => {
    const temp = [];
    if (sponsorsData.length !== 0) {
      sponsorsData.map(async (item, key) => {
        const res = await getSponsorImage(item.imageUrl);
        const preview = res.data;

        const {
          createdBy,
          dateCreated,
          dateUpdated,
          id,
          imageUrl,
          isActive,
          sponsorName,
          secret,
          studies,
          updatedBy,
        } = item;

        temp.push({
          createdBy,
          dateCreated,
          dateUpdated,
          id,
          imageUrl,
          isActive,
          sponsorName,
          secret,
          studies,
          updatedBy,
          previewImg: `${preview}`,
        });
      });
      setNewSponsor(temp);
      setLoad(false);
    }
  };

  const showRolesAndSites = (e, activeSponsor, activeStudy, sID) => {
    e.preventDefault();

    if (studyRoleSitesObject.some((study) => study.studyId === sID)) {
      setActiveArrow(sID);
      const currentStudyRole = studyRoleSitesObject.find(
        (study) => study.studyId === sID
      );
      if (currentStudyRole && Object.keys(currentStudyRole).length > 0) {
        setActiveStudyRole(currentStudyRole);
      } else {
        setActiveStudyRole({
          studyId: sID,
          study: `${activeSponsor} - ${activeStudy}`,
          roles: activeRole,
          sites: activeArrSites,
        });
      }
    } else {
      setActiveArrStudies([...activeArrStudies, sID]);
      setStudyRoleSitesObject([
        ...studyRoleSitesObject,
        {
          studyId: sID,
          study: `${activeSponsor} - ${activeStudy}`,
          roles: activeRole,
          sites: [],
        },
      ]);
      setActiveStudyRole({
        studyId: sID,
        study: `${activeSponsor} - ${activeStudy}`,
        roles: activeRole,
        sites: [],
      });
      setActiveArrow(sID);
    }
    if (showRowSite === sID) {
      setShowRowSite("");
    } else {
      setShowRowSite(sID);
    }
    setShowRolesActive(true);
    setActiveSponsor(activeSponsor);
    setActiveStudy(activeStudy);
  };

  const getStudyImage = async () => {
    if (checkStudy.length > 0) {
      Promise.allSettled(
        checkStudy.map(async (study) => {
          try {
            const res = await getSponsorImage(study.imageUrl);
            const preview = res.data;

            const {
              createdBy,
              dateCreated,
              dateUpdated,
              id,
              imageUrl,
              isActive,
              name,
              secret,
              studies,
              updatedBy,
            } = study;

            return {
              createdBy,
              dateCreated,
              dateUpdated,
              id,
              imageUrl,
              isActive,
              name,
              secret,
              studies,
              updatedBy,
              previewImg: `${preview}`,
            };
          } catch (err) {
            console.log("Error: ", err);
            return null;
          }
        })
      )
        .then((response) => {
          // setEnrolledStudy(response.map(({ value }) => value));
          setCallStudyImageApi(false);
          if (notifyNext) {
            notifySponsor();
            setValue(2);
          }
          setLoad(false);
        })
        .catch((err) => {
          console.log("Error: ", err);
          setLoad(false);
          setCallStudyImageApi(false);
        });
    } else if (checkStudy.length === 0) {
      // allStudiesRemoved();
      // setEnrolledStudy([]);
      setLoad(false);
    }
  };

  // const getEnrolledStudiesData = async () => {
  //   setLoad(true);
  //   try {
  //     const res = await getEnrolledStudiesDataService(id);
  //     console.log("res", res);
  //     if (res?.status) {
  //       setLoad(false);
  //       setEnrolledStudy(res?.data?.result?.allStudyRoles);
  //     }
  //   } catch (err) {
  //     setLoad(false);
  //     console.log("Error :", err?.message);
  //   }
  // };

  const handleSelectAllSites = (e, sID) => {
    e.preventDefault();
    const temp = rows2.map((site) => site.name);

    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].sites = temp;
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      sites: temp,
    }));
    setActiveArrSites(temp);
  };

  const handleActiveSites = (e, siteName, sID) => {
    e.preventDefault();
    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].sites = [
        ...studyObject[studyIndex].sites,
        siteName,
      ];
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      sites: [...study.sites, siteName],
    }));
  };

  const [items, setItems] = useState([]);

  const addItem = (newItem) => {
    setItems((prevItems) => {
      // Check if the new item already exists in the array
      if (!prevItems.includes(newItem)) {
        // If not, add the new item
        return [...prevItems, newItem];
      }
      // Otherwise, return the existing array
      return prevItems;
    });
  };

  const roleHandler = (role, sID) => {
    addItem(activeStudyRole?.studyId);
    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].roles = role;
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      roles: role,
    }));
  };

  const fetchStudyRoles = async () => {
    try {
      setLoad(true);
      const res = await getStudyRoles();

      setStudyRoles(res.data);
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const { singleUserData: user, isLoadingSingleUser } = useUserDetails();

  const fetchUserById = async (id) => {
    try {
      const res = await getUserById(id);
      const data = {
        firstName: res.data.result?.userData.firstName,
        lastName: res.data.result?.userData.lastName,
        middleName: res.data.result?.userData.middleName,
        email: res.data.result?.userData.email,
        phoneNumber: res.data.result?.userData.phoneNumber,
      };
      isAdminChecked ? resetAdmin(data) : resetUser(data);
      setFirstName(res.data.result?.userData.firstName);
      setMiddleName(res.data.result?.userData.middleName);
      setEmail(res.data.result?.userData.email);
      setLastName(res.data.result?.userData.lastName);
      setTestPhoneUser(res?.data.result?.userData.phoneNumber);
      setIsActive(res.data.result?.userData.isActive);
      if (res.data.result?.userData?.selectedSponsor?.length > 0) {
        res.data.result?.userData?.selectedSponsor?.forEach((sponsor) => {
          if (!uniqueIds.includes(sponsor.id)) {
            uniqueIds.push(sponsor.id);
          }
        });

        res.data.result?.userData?.studies?.forEach((sponsor) => {
          setActiveArrow([...activeArrow, sponsor?.id]);
        });

        setNotifyNext(false);
        setCheckedSponsorId(uniqueIds);

        // setActiveArrow(res.data.result?.userData?.studies[1]?.id);
      }
      if (res.data.result?.userData?.studies?.length > 0) {
        res.data.result?.userData?.studies?.forEach((study) => {
          if (!uniqueIds2?.includes(study.id)) {
            uniqueIds2?.push(study.id);
          }
        });

        setItems(uniqueIds2);
      }

      setEnrolledStudy(res?.data?.result?.userData?.selectedStudies);

      setAdminFirstName(res.data.result?.userData.firstName);
      setAdminMiddleName(res.data.result?.userData.middleName);
      setAdminEmail(res.data.result?.userData.email);
      setAdminLastName(res.data.result?.userData.lastName);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      setLoad(true);
      fetchUserById(id);
    } else {
      navigate("/error");
    }
  }, [id, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showDialog) {
        event.preventDefault();
        event.returnValue = "";
        return "Changes you made will not be saved. Are you sure?";
      }
    };

    const handlePopState = (event) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (!userConfirmed) {
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showDialog]);

  React.useEffect(() => {
    setLoad(true);
    fetchSponsors();
    // fetchStudyRoles();
    // getEnrolledStudiesData();
  }, []);

  React.useEffect(() => {
    if (value !== 0) setIsSelectedSponsorChange(true);
  }, [checkedSponsorId]);

  // React.useEffect(() => {
  //   setNotifyNext(false);
  //   setTimeout(() => {
  //     if (checkedSponsorId?.length > 0) {
  //       submitCheckedSponsors();
  //     }
  //   }, 1000);
  // }, [checkedSponsorId]);

  React.useEffect(() => {
    setLoad(true);
    getImage();
  }, [sponsorsData]);

  React.useEffect(() => {
    if (callStudyImageApi === true) {
      if (notifyNext) {
        setLoad(true);
      }
      getStudyImage();
    }
  }, [callStudyImageApi]);

  // React.useEffect(() => {
  //   console.log("studyRoleSitesObject ===> ", studyRoleSitesObject);
  // }, [studyRoleSitesObject]);

  const handleRole1Click = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleRole1Close = () => {
    setAnchorEl1(null);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset: resetUser,
  } = useForm({
    resolver: yupResolver(validationSchemaUser),
  });

  const {
    register: registerAdmin,
    handleSubmit: handleSubmitAdmin,
    watch: watchAdmin,
    control: adminControl,
    formState: { errors: errorsAdmin },
    reset: resetAdmin,
  } = useForm({
    resolver: yupResolver(validationSchemaAdmin),
  });

  const {
    register: registerSponsor,
    handleSubmit: handleSubmitSponsor,
    watch: watchSponsor,
    formState: { errors: errorsSponsor },
  } = useForm({
    resolver: yupResolver(validationSchemaSponsor),
  });

  const {
    register: registerStudy,
    handleSubmit: handleSubmitStudy,
    watch: watchStudy,
    formState: { errors: errorsStudy },
  } = useForm({
    resolver: yupResolver(validationSchemaStudy),
  });

  useEffect(() => {
    if (user) {
      const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        email: user.email,
        phone: user.phoneNumber,
      };
      resetUser(data);
    }
  }, [user, resetUser]);

  const generatePassword = () => {
    var length = 8;
    var pass = "Genesis@123";
    setStaticPass(pass);
    return pass;
  };

  const cancelAddUser = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/all-users");
    }
  };

  const cancelUserSponsor = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/all-users");
    }
  };

  const cancelUserStudy = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/all-users");
    }
  };

  // const showRolesAndSites = (e, activeSponsor, activeStudy) => {
  //   setShowRolesActive(!showRolesActive);
  //   setActiveSponsor(activeSponsor);
  //   setActiveStudy(activeStudy);
  // };

  const onSubmitUser = async (data, e) => {
    // e.preventDefault();
    if (testPhoneUser === "") {
      notifyUserNumber();
    } else {
      try {
        // const firstName = data.firstName;
        // const middleName = data.middleName;
        // const email = data.email;
        // const lastName = data.lastName;
        const userData = {
          firstName,
          middleName,
          email,
          lastName,
          phoneNumber: testPhoneUser,
          password: isStaticChecked ? staticPass : "",
          isAdmin: false,
          isActive: isActive,
          staticPass: isStaticChecked,
          selectedStudies: items,
        };

        setLoad(true);

        const res = await editUser(id, userData);

        if (res.status === 200) {
          setLoad(false);
          setRoleUserId(res.data.result);
          // notifyUser(true);
          // setValue(1);
        } else {
          setLoad(false);
          reqFailed();
        }
      } catch (err) {
        setLoad(false);
        console.log("submit error: ", err);
        reqFailed();
      }
    }
  };

  const getAllStudyRoles = async () => {
    try {
      const resp = await axios.get(
        "https://genesisapi.augursapps.com/app/StudyRoles/GetAllStudyRoles?pageNumber=1&pageSize=100000000"
      );

      if (resp?.data) {
        setStaticStudyRoles(resp?.data);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   getAllStudyRoles();
  // }, []);

  const onSubmitAdmin = async (data, e) => {
    // e.preventDefault();
    if (testPhone === "") {
      notifyPhoneNumber();
    } else {
      try {
        setLoad(true);
        // const firstName = data.adminFirstName;
        // const middleName = data.adminMiddleName;
        // const email = data.adminEmail;
        // const lastName = data.adminLastName;
        const adminData = {
          firstName,
          middleName,
          email,
          lastName,
          phoneNumber: testPhoneUser,
          password: isStaticChecked ? staticPass : "",
          isAdmin: true,
          isActive: isActive,
          staticPass: isStaticChecked,
          selectedStudies: items,
        };

        const res = await editUser(id, adminData);
        if (res.status === 200) {
          setLoad(false);
          setRoleUserId(res.data.result);
          // notifyAdmin();
          // setValue(1);
        } else {
          setLoad(false);
          reqFailed();
        }
      } catch (err) {
        console.log("submit error: ", err);
      }
    }
  };

  const onSubmitSponsor = async (data, e) => {
    e.preventDefault();
    setLoad(true);
    // setNotifyNext(false);
    try {
      // setFormValues(data);
      // await submitCheckedSponsors();
      const res = await saveSponsorsAndGetStudiesService(id, checkedSponsorId);
      if (res?.status) {
        setLoad(false);
        setIsSelectedSponsorChange(false);
        setEnrolledStudy(res?.data?.result);
        notifySponsor();
      }
    } catch (err) {
      setLoad(false);
      requestFailed(err?.message);
      console.log("submit error: ", err);
    }
  };

  const onSubmitStudy = (data, e) => {
    // e.preventDefault();
    // setLoad(true);
    // try {
    //   setShowDialog(false);
    //   isAdminChecked ? onSubmitAdmin() : onSubmitUser();
    //   setTimeout(() => {
    //     setShowDialog(false);
    //     notifyStudy(true);
    //     navigate("/all-users");
    //     setLoad(false);
    //   }, 1000);
    // } catch (err) {
    //   console.log("submit error: ", err);
    // }
    updateSiteAndRoles();
  };

  const handleFirstNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setFirstName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleMiddleNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setMiddleName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleLastNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setLastName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleEmailChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setEmail(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminFirstNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminFirstName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminMiddleNameChange = (event) => {
    setAdminMiddleName(event.target.value);
  };

  const handleAdminLastNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminLastName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminEmailChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminEmail(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleChange = (e, newValue) => {
    if (isSponsorChange) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to change the tab ?"
      );
      if (confirm) {
        setIsSelectedSponsorChange(false);
        setValue(newValue);
      } else {
        e.preventDefault();
      }
    } else setValue(newValue);
  };

  const handleStaticCheck = () => {
    generatePassword();
    setIsStaticChecked(!isStaticChecked);
    setShowDialog(true);
  };

  // const handleAdminCheck = () => {
  //   setIsAdminChecked(!isAdminChecked);
  //   setShowDialog(true);
  // };

  const handleAdminCheck = () => {
    setIsAdminChecked((prevState) => {
      const newAdminChecked = !prevState;

      if (newAdminChecked) {
        setAdminFirstName(firstName);
        setAdminMiddleName(middleName);
        setAdminEmail(email);
        setAdminLastName(lastName);
        setTestPhoneUser(testPhoneUser);
        setStaticPass(staticPass);
      } else {
        setFirstName(adminFirstName);
        setMiddleName(adminMiddleName);
        setEmail(adminEmail);
        setLastName(adminLastName);
        setTestPhoneUser(testPhoneUser);
        setStaticPass(staticPass);
      }

      return newAdminChecked;
    });

    setShowDialog(true);
  };

  const handleIsActive = () => {
    setIsActive(!isActive);
    setShowDialog(true);
  };

  const onPhoneChange = (value) => {
    setTestPhone(value);
  };

  const onPhoneUserChange = (value) => {
    setTestPhoneUser(value);
    setShowDialog(true);
  };

  const handleSponsorId = async (item, event, index) => {
    setShowDialog(true);
    // setLoad(true);
    try {
      const isChecked = event.target.checked;
      setSponsorChecked((sponsorChecked) => {
        return sponsorChecked.map((c, i) => {
          if (i === index) return isChecked;
          return c;
        });
      });
      if (isChecked) {
        const id = item;

        if (checkedSponsorId.includes(id)) {
          setCheckedSponsorId(
            checkedSponsorId.filter((sponsor) => sponsor !== id)
          );
          const filterSponsors = checkStudy.filter((study) => study.id !== id);
          setCheckStudy(filterSponsors);
          return true;
        }

        const checkedArray = [];
        checkedArray.push(id);

        setCheckedSponsorId([...checkedSponsorId, ...checkedArray]);
      } else {
        setLoad(true);
        const id = item;

        const filterChecked = checkedSponsorId.filter(
          (sponsor) => sponsor !== id
        );

        setCheckedSponsorId(
          checkedSponsorId.filter((sponsor) => sponsor !== id)
        );
        const filterSponsors = checkStudy.filter((study) => study.id !== id);
        setCheckStudy(filterSponsors);
        setLoad(false);
        // studyRemoved();
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err);
      noStudyFound();
    }
  };

  const submitCheckedSponsors = async () => {
    try {
      setLoad(true);
      const responses = await Promise.all(
        checkedSponsorId.map(async (id) => {
          const res = await getSponsorBasedStudy(id);
          if (res.status === 200) {
            return res.data;
          } else {
            return null;
          }
        })
      );
      if (responses.length) {
        setCheckStudy(responses);
        setCallStudyImageApi(true);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  const isDisabled = (tabIndex) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };

  const tabStyles = {
    fontSize: "12px",
    fontWeight: "700",
  };

  const handleNavigation = useCallback(
    (e, path) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (userConfirmed) {
          setShowDialog(false);
          e.preventDefault();
          navigate(path);
        }
      } else {
        navigate(path);
      }
    },
    [showDialog, navigate]
  );

  const updateUserDetails = async () => {
    setLoad(true);
    try {
      let data = {
        firstName: isAdminChecked ? adminFirstName : firstName,
        middleName: middleName,
        email: isAdminChecked ? adminEmail : email,
        lastName: isAdminChecked ? adminLastName : lastName,
        phoneNumber: testPhoneUser,
        password: isStaticChecked ? staticPass : "",
        isAdmin: false,
        isActive: isActive,
        staticPass: isStaticChecked,
        selectedStudies: [],
        studies: [],
        sponsors: sponsorChecked,
      };
      const res = await editUser(id, data);
      if (res?.status) {
        notifyUser();
        setValue(0);
        setLoad(false);
      }
    } catch (err) {
      requestFailed(err?.message);
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const handleDivClick = async (e, item) => {
    e.preventDefault();
    setSelectedEnrolledStudies(item?.id);
    setLoad(true);
    try {
      // let url = `${item?.apiUrl}User/GetStudyRoleAndSiteData?UserGuid=${id}&`;
      const res = await getSiteAndRoleDataService(id, item?.apiUrl);
      if (res?.status) {
        setLoad(false);
        setSiteRoleData(res?.data?.result?.allSites);
        setStudyRoleData(res?.data?.result?.allStudyRoles);
        setSelectedSiteRoles(res?.data?.result?.selectedSites);
        setSelectedStudyRoles(res?.data?.result?.selectedStudyRoles);
        // setSelectedSiteRoles([]);
        // setSelectedStudyRoles([]);
      }
    } catch (err) {
      setLoad(false);
      requestFailed(err?.message);
      console.log("Error :", err?.message);
    }
  };

  const handleSiteRoleDivClick = (e, item) => {
    e.preventDefault();

    setSelectedSiteRoles((prevSelectedItems) =>
      prevSelectedItems.includes(item?.id)
        ? prevSelectedItems.filter((i) => i !== item?.id)
        : [...prevSelectedItems, item?.id]
    );
  };

  const handleStudyRoleDivClick = (e, item) => {
    e.preventDefault();

    setSelectedStudyRoles((prevSelectedItems) =>
      prevSelectedItems.includes(item?.id)
        ? prevSelectedItems.filter((i) => i !== item?.id)
        : [...prevSelectedItems, item?.id]
    );
  };

  const updateSiteAndRoles = async () => {
    if (
      (selectedSiteRoles?.length !== 0 && selectedStudyRoles.length === 0) ||
      (selectedSiteRoles?.length === 0 && selectedStudyRoles?.length !== 0)
    ) {
      if (selectedSiteRoles?.length === 0) {
        window.alert("Please select atleast one Study Site.");
      } else {
        window.alert("Please select atleast one Study Role.");
      }
    } else {
      setLoad(true);
      try {
        const ids = mapIds(selectedSiteRoles, selectedStudyRoles);
        const payload = {
          email: email,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          phoneNumber: testPhoneUser,
          sponsors: null,
          studies: [],
          selectedStudies: [
            {
              studyId: selectedEnrolledStudies,
              studyUserRoleData: {
                userUUID: id,
                selectedStudyRoles: mapIds(
                  selectedSiteRoles,
                  selectedStudyRoles
                ),
              },
            },
          ],
        };
        const res = await updateStudyAndSiteDataService(id, payload);
        if (res?.status) {
          setLoad(false);
          notifyStudy(true);
        }
      } catch (err) {
        setLoad(false);
        reqFailed(err?.message);
        console.log("Error: ", err?.message);
      }
    }
  };

  function mapIds(siteIds, studyRoleIds) {
    
    if (siteIds?.length === 0 && studyRoleIds?.length === 0) {
      return null;
    }
    const result = [];
    const maxLength = Math.max(siteIds.length, studyRoleIds.length);

    for (let i = 0; i < maxLength; i++) {
      result.push({
        siteId: siteIds[i % siteIds.length],
        studyRoleId: selectedStudyRoles[i % selectedStudyRoles.length],
      });
    }

    return result;
  }

  console.log(selectedStudyRoles, "1225");
  

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />

          <p className="admin-link" style={{ fontWeight: "600" }}>
            <button onClick={(e) => handleNavigation(e, "/homepage")}>
              <Link>Home</Link>
            </button>
            {" > "}
            <button onClick={(e) => handleNavigation(e, "/all-users")}>
              <Link>All Users</Link>
            </button>
            {" > "}
            {/* <Link to="/add-user">Edit User</Link> */}
            <span
              style={{
                color: "#4b8ac0",
                cursor: "pointer",
              }}
            >
              Update User
            </span>{" "}
            -{" "}
            <b style={{ color: "gray" }}>
              {(isAdminChecked ? adminFirstName : firstName) +
                " " +
                middleName +
                " " +
                (isAdminChecked ? adminLastName : lastName)}
            </b>
          </p>
          <Box sx={{ marginTop: "2%" }}>
            <Box sx={{ width: "27%", borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab sx={tabStyles} label="User" {...a11yProps(0)} />
                <Tab sx={tabStyles} label="Sponsors" {...a11yProps(1)} />
                <Tab sx={tabStyles} label="Studies" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {isAdminChecked ? (
                <>
                  <form>
                    <p className="user-heading">User (Admin)</p>
                    <div className="userForm-body">
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">First Name *</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={adminFirstName}
                            type="text"
                            name="adminFirstName"
                            {...registerAdmin("adminFirstName", {
                              required: true,
                            })}
                            onChange={handleAdminFirstNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminFirstName?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Middle Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={adminMiddleName}
                            type="text"
                            name="adminMiddleName"
                            {...registerAdmin("adminMiddleName", {
                              required: true,
                            })}
                            onChange={handleMiddleNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminMiddleName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">Email *</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={adminEmail}
                            type="email"
                            name="adminEmail"
                            {...registerAdmin("adminEmail", { required: true })}
                            onChange={handleAdminEmailChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminEmail?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Last Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={adminLastName}
                            type="text"
                            name="adminLastName"
                            {...registerAdmin("adminLastName", {
                              required: true,
                            })}
                            onChange={handleAdminLastNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminLastName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">
                            Phone Number *
                          </label>
                          <PhoneInput
                            country={"us"}
                            enableSearch={true}
                            inputProps={{
                              name: "testPhoneUser",
                              required: true,
                            }}
                            name="testPhoneUser"
                            onChange={onPhoneUserChange}
                            value={testPhoneUser}
                          />
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isAdminChecked}
                                    onChange={handleAdminCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Admin</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isStaticChecked}
                                    onChange={handleStaticCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">
                                    Static Password
                                  </div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isActive}
                                    onChange={handleIsActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Active</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={9}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={4}>
                          {isStaticChecked ? (
                            <p>Static Password: {staticPass}</p>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}></Col>
                        <Col md={3}></Col>
                      </Row>
                      <Row style={{ marginTop: "3%" }}>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-cancel-btn"
                              onClick={cancelAddUser}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-create-btn-md"
                              type="button"
                              onClick={(e) => {
                                const regex =
                                  /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

                                if (adminFirstName === "") {
                                  notifyFirstName();
                                  setLoad(false);
                                } else if (adminEmail === "") {
                                  notifyEmail();
                                  setLoad(false);
                                } else if (!regex.test(adminEmail)) {
                                  notifyEmailVerify();
                                  setLoad(false);
                                } else if (testPhoneUser === "") {
                                  notifyUserNumber();
                                  setLoad(false);
                                } else {
                                  updateUserDetails();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form>
                    <p className="user-heading">User (Non Admin)</p>
                    <div className="userForm-body">
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">First Name *</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={firstName}
                            type="text"
                            name="firstName"
                            {...register("firstName", { required: true })}
                            onChange={handleFirstNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.firstName?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Middle Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={middleName}
                            type="text"
                            name="middleName"
                            {...register("middleName", { required: true })}
                            onChange={handleMiddleNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.middleName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">Email *</label>
                          <input
                            className="nameSponsorField"
                            defaultValue={email}
                            type="text"
                            name="email"
                            {...register("email", { required: true })}
                            onChange={handleEmailChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.email?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Last Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={lastName}
                            type="text"
                            name="lastName"
                            {...register("lastName", { required: true })}
                            onChange={handleLastNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.lastName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">
                            Phone Number *
                          </label>
                          {/* <Controller
                            render={({ field }) => ( */}
                          <PhoneInput
                            country={"us"}
                            enableSearch={true}
                            inputProps={{
                              name: "testPhoneUser",
                              required: true,
                            }}
                            name="testPhoneUser"
                            onChange={onPhoneUserChange}
                            value={testPhoneUser}
                          />
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isAdminChecked}
                                    onChange={handleAdminCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Admin</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isStaticChecked}
                                    onChange={handleStaticCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">
                                    Static Password
                                  </div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isActive}
                                    onChange={handleIsActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Active</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={9}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={4}>
                          {isStaticChecked ? (
                            <p>Static Password: {staticPass}</p>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}></Col>
                        <Col md={3}></Col>
                      </Row>
                      <Row style={rowStyles}>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-cancel-btn"
                              onClick={cancelAddUser}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-create-btn-md"
                              type="button"
                              onClick={() => {
                                const regex =
                                  /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

                                if (firstName === "") {
                                  notifyFirstName();
                                  setLoad(false);
                                } else if (email === "") {
                                  notifyEmail();
                                  setLoad(false);
                                } else if (!regex.test(email)) {
                                  notifyEmailVerify();
                                  setLoad(false);
                                } else if (testPhoneUser === "") {
                                  notifyUserNumber();
                                  setLoad(false);
                                } else {
                                  // notifyAdmin();
                                  // setValue(0);
                                  // setLoad(false);
                                  updateUserDetails();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <form onSubmit={handleSubmitSponsor(onSubmitSponsor)}>
                <p className="user-heading">Sponsors (Active)</p>
                <div className="sponsor-cols">
                  {newSponsor &&
                    newSponsor.map((item, index) => (
                      <div className="sponsorSelectBody" key={index}>
                        <div className="sponsorBodyUser">
                          <Checkbox
                            {...label}
                            checked={checkedSponsorId?.includes(item?.id)}
                            {...registerSponsor("checkbox", { required: true })}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 24,
                                color: "#3661eb",
                              },
                            }}
                            onChange={(event) =>
                              handleSponsorId(item.id, event, index)
                            }
                          />
                          <img
                            onClick={() =>
                              // Simulating a checkbox toggle when the image is clicked
                              handleSponsorId(
                                item.id,
                                {
                                  target: {
                                    checked: !checkedSponsorId?.includes(
                                      item?.id
                                    ),
                                  },
                                },
                                index
                              )
                            }
                            src={item.previewImg}
                            className="sponsorImageUser"
                          />
                          <label
                            className="sponsorSubUser"
                            onClick={() =>
                              // Simulating a checkbox toggle when the image is clicked
                              handleSponsorId(
                                item.id,
                                {
                                  target: {
                                    checked: !checkedSponsorId?.includes(
                                      item?.id
                                    ),
                                  },
                                },
                                index
                              )
                            }
                          >
                            {item.sponsorName}
                          </label>
                        </div>

                        {/* <span
                        style={{
                          color: "#3661eb",

                          fontSize: "12px",
                        }}
                      >
                        {errorsSponsor.checkbox?.message
                          ? "Atleast one sponsor is required"
                          : ""}
                      </span> */}
                      </div>
                    ))}
                </div>
                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-cancel-btn"
                        onClick={cancelUserSponsor}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-create-btn-md"
                        type="submit"
                        onClick={() => {
                          if (checkedSponsorId == 0) {
                            checkBoxOne();
                            setLoad(false);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <p
                className="user-heading"
                style={{ textDecoration: "underline" }}
              >
                Enrolled Studies
              </p>
              <form onSubmit={handleSubmitStudy(onSubmitStudy)}>
                <div
                  // className="sponsor-cols-studies"
                  style={{ marginLeft: "18px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      maxHeight: "30vw",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div>
                      <h4>Studies</h4>
                      {enrolledStudy &&
                        enrolledStudy.length > 0 &&
                        enrolledStudy.map((item, index) => (
                          <div
                            className="study-array"
                            style={{
                              width: "25vw",
                              cursor: "pointer",
                              border:
                                selectedEnrolledStudies === item?.id
                                  ? "4px solid lightblue"
                                  : "1px solid lightgrey",
                              borderRadius: "5px",
                              paddingLeft: "15px",
                              padding: "5px",
                              margin: "10px",
                              marginLeft: "0px",
                            }}
                            key={index}
                          >
                            {selectedEnrolledStudies === item?.id && (
                              <input
                                type="checkbox"
                                checked={true}
                                style={{
                                  // position: "absolute",
                                  top: "-20px",
                                  left: "10px",
                                }}
                                readOnly
                              />
                            )}
                            <div className="study-card">
                              <h5>
                                {item?.name || item?.studyName} -{" "}
                                <b>
                                  {sponsorsData &&
                                    sponsorsData.find(
                                      (ele) => ele?.id === item?.sponsorId
                                    )?.sponsorName}
                                </b>
                              </h5>
                              <button
                                onClick={(e) => handleDivClick(e, item)}
                                style={{
                                  background: "none",
                                  color: "#007bff",
                                  border: "none",
                                  padding: "inherit",
                                  font: "inherit",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Roles and Sites
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    {siteRoleData && siteRoleData?.length > 0 && (
                      <div>
                        <h4 className="ps-3">Sites</h4>
                        {siteRoleData &&
                          siteRoleData.length > 0 &&
                          siteRoleData.map((item, index) => (
                            <div
                              className="study-array"
                              onClick={(e) => handleSiteRoleDivClick(e, item)}
                              style={{
                                width: "25vw",
                                cursor: "pointer",
                                border: selectedSiteRoles?.includes(item?.id)
                                  ? "4px solid lightblue"
                                  : "1px solid lightgrey",
                                borderRadius: "5px",
                                paddingLeft: "15px",
                                padding: "5px",
                                margin: "10px",
                              }}
                              key={index}
                            >
                              {selectedSiteRoles?.includes(item?.id) && (
                                <input
                                  type="checkbox"
                                  checked={true}
                                  style={{
                                    // position: "absolute",
                                    top: "-20px",
                                    left: "10px",
                                  }}
                                  readOnly
                                />
                              )}
                              <div className="study-card">
                                <h5>{item?.name}</h5>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {studyRoleData && studyRoleData?.length > 0 && (
                      <div>
                        <h4 className="ps-3">Roles</h4>
                        {studyRoleData &&
                          studyRoleData.length > 0 &&
                          studyRoleData.map((item, index) => (
                            <div
                              onClick={(e) => handleStudyRoleDivClick(e, item)}
                              className="study-array"
                              style={{
                                width: "25vw",
                                cursor: "pointer",
                                border: selectedStudyRoles?.includes(item?.id)
                                  ? "4px solid lightblue"
                                  : "1px solid lightgrey",
                                borderRadius: "5px",
                                paddingLeft: "15px",
                                padding: "5px",
                                margin: "10px",
                              }}
                              key={index}
                            >
                              {selectedStudyRoles?.includes(item?.id) && (
                                <input
                                  type="checkbox"
                                  checked={true}
                                  style={{
                                    // position: "absolute",
                                    top: "-20px",
                                    left: "10px",
                                  }}
                                  readOnly
                                />
                              )}
                              <div className="study-card">
                                <h5>{item?.description}</h5>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-cancel-btn"
                        onClick={cancelUserStudy}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-create-btn-md"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </TabPanel>
          </Box>
        </div>
      )}
    </>
  );
};

export default AddUser;
