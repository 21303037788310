import { studyServer } from "./study_axios";

export const ValidateImport = (extension, delimiter, importFile) => {
  const formData = new FormData();
  formData.append("File", importFile);
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `/ValidateImport?Delimiter=${delimiter}&Extension=${extension}`,
        formData
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const ImportDataAPI = (importedObjects) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/ImportTranslationData`, importedObjects)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const getAllExport = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/GetExportTranslationData")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};