import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from "@mui/material";
import { FaFileDownload } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import "../../StudySetup.css";
import "../StudyFiles/StudyFiles.css";
import "./Translation.css";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import {
  ImportDataAPI,
  ValidateImport,
} from "../../../../../services/Translation";

const Import = () => {
  const navigate = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [importedObjects, setImportedObjects] = React.useState([]);

  const [delimiterData, setDelimiterData] = React.useState([
    { id: 1, name: "," },
    { id: 2, name: "|" },
  ]);

  const [extensionData, setExtensionData] = React.useState([
    { id: 1, name: ".csv" },
    { id: 2, name: ".txt" },
  ]);

  const importSchema = yup.object().shape({
    delimiter: yup.string().required("This field is required"),
    extension: yup.string().required("This field is required"),
    importFile: yup.mixed().required("This field is required"),
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delimiter: delimiterData[0]?.id,
      extension: extensionData[0]?.id,
    },
    mode: "onChange",
    resolver: yupResolver(importSchema),
  });

  const notify = () =>
    toast.success("Translation Imported Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  useEffect(() => {
    setValue("delimiter", delimiterData[0]?.id);
    setValue("extension", extensionData[0]?.id);
  }, [setValue, delimiterData, extensionData]);

  const getSelectedExtension = () => {
    const selectedExtension = extensionData.find(
      (ext) => ext.id === getValues("extension")
    );
    return selectedExtension ? selectedExtension.name.toLowerCase() : "";
  };

  const handleDownloadTemplate = () => {
    const selectedExtension = getSelectedExtension();
    const headers = ["Language", "Key", "Text"];

    if (selectedExtension === ".csv") {
      const csvContent = `data:text/csv;charset=utf-8,${headers.join(",")}\n`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "template.csv");
      link.click();
    } else if (selectedExtension === ".txt") {
      // Use comma separator to mimic CSV format, but in a .txt file
      const txtContent = `Language,Key,Text\n`; // No tabs, just commas
      const encodedUri = encodeURI(
        `data:text/plain;charset=utf-8,${txtContent}`
      );
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "template.txt");
      link.click();
    }
  };

  const [isValidated, setIsValidated] = React.useState(false);

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);

      const { delimiter, extension, importFile } = data;

      const selectedDelimiter = delimiterData.find(
        (item) => item.id === Number(delimiter)
      )?.name;

      const selectedExtension = extensionData.find(
        (item) => item.id === Number(extension)
      )?.name;

      if (!selectedDelimiter || !selectedExtension) {
        throw new Error("Invalid delimiter or extension selected");
      }
      const res = await ValidateImport(
        selectedExtension,
        selectedDelimiter,
        importFile
      );
      if (res?.data?.result?.importedObjects?.length > 0) {
        setImportedObjects(res.data.result.importedObjects);
        setLoad(false);
        notify();
        setIsValidated(true);
      } else {
        throw new Error(res?.data?.result?.errors[0] || "Validation failed");
      }
    } catch (err) {
      setImportedObjects([]);
      setIsValidated(false);
      setLoad(false);
      setShowDialog(false);
      console.error("Error:", err.message);
      requestFailed(err.message);
    }
  };

  const onImport = async () => {
    try {
      if (!isValidated || importedObjects.length === 0) {
        throw new Error("No valid data to import.");
      }

      setLoad(true);

      const payload = importedObjects.map(({ entity }) => ({
        entity: {
          key: entity.key,
          language: entity.language,
          text: entity.text,
          description: entity.description,
        },
        validationErrors: [],
      }));

      const res = await ImportDataAPI(payload);

      if (res?.status) {
        notify();
        setIsValidated(false);
        setImportedObjects([]);
      } else {
        throw new Error(res.message || "Import failed");
      }
    } catch (err) {
      requestFailed(err.message);
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/treatment");
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 12,
    width: "390px",
    height: "5px",
  };

  const selectBoxStyles = {
    fontSize: 12,
    width: "390px",
    height: "2px",
  };

  const langForm = {
    langFlexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      width: "1050px",
    },
    langError: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "865px",
      marginTop: "-5px",
    },
    langErrorText: {
      color: "red",
      marginLeft: "5px",
      marginTop: "18px",
      marginBottom: "20px",
      fontSize: "12px",
      fontWeight: "500",
    },
  };

  const rowSpacing = {
    marginTop: "2%",
  };

  const selectStyles = {
    width: 418,
    marginLeft: 0.3,
  };

  return (
    <>
      {load ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/study-settings/translations">
              Translation
            </Link>{" "}
            |{" "}
            <Link to="/study-management/study-settings/translations/import">
              Import
            </Link>
          </p>
          <Row style={{ marginTop: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Import</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <Row>
              <Col md={12}>
                <div className="language-flexbox">
                  <div className="language-label-body">
                    <label>
                      Delimiter <span className="error-highlight">*</span>
                    </label>
                  </div>
                  <div className="createDispensationInput">
                    <FormControl sx={selectStyles}>
                      <Select
                        name="delimiter"
                        defaultValue={delimiterData[0]?.id || ""}
                        inputProps={{
                          style: selectBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...register("delimiter", {
                          onChange: (e) => {
                            setValue("delimiter", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {delimiterData.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {errors.delimiter && (
                  <div style={langForm.langFlexEnd}>
                    <div style={langForm.langError}>
                      <span style={langForm.langErrorText}>
                        {errors.delimiter.message}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="language-flexbox">
                  <div className="language-label-body">
                    <label>
                      Extension <span className="error-highlight">*</span>
                    </label>
                  </div>
                  <div className="createDispensationInput">
                    <FormControl sx={selectStyles}>
                      <Select
                        name="extension"
                        defaultValue={extensionData[0]?.id || ""}
                        inputProps={{
                          style: selectBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...register("extension", {
                          onChange: (e) => {
                            setValue("extension", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {extensionData.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {errors.extension && (
                  <div style={langForm.langFlexEnd}>
                    <div style={langForm.langError}>
                      <span style={langForm.langErrorText}>
                        {errors.extension.message}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="language-flexbox">
                  <div className="language-label-body">
                    <label>Template</label>
                  </div>
                  <div className="createDispensationInput">
                    <span
                      onClick={handleDownloadTemplate}
                      className="template-import-btn cursor-pointer"
                    >
                      Download <FaFileDownload style={{ fontSize: "14px" }} />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="language-flexbox">
                  <div className="language-label-body">
                    <label>
                      Import File <span className="error-highlight">*</span>
                    </label>
                  </div>
                  <div className="createDispensationInput">
                    <FileUpload
                      accept={`${getSelectedExtension()}`}
                      onChange={(file) => {
                        setValue("importFile", file, { shouldValidate: true });
                        setUploadedFile(file);
                        setShowDialog(true);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {errors.importFile && (
                  <div style={langForm.langFlexEnd}>
                    <div style={langForm.langError}>
                      <span style={langForm.langErrorText}>
                        {errors.importFile.message}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <div className="createVisitButtonBody">
              <button
                type="submit"
                className="validateImportBtn"
                onClick={handleSubmit(onSubmit)}
              >
                Validate Import
              </button>
              <button
                type="submit"
                onClick={onImport}
                disabled={!isValidated}
                className={`rounded-md font-semibold ${
                  isValidated
                    ? " validateImportBtn hover:bg-blue-700"
                    : "bg-gray-300 px-10 py-4 text-gray-500 cursor-not-allowed"
                }`}
              >
                Import
              </button>
            </div>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back To Create Study
                  </button>
                </div>
              </Link>
            </Col>
          </Row>

          <div className="p-4 mt-16">
            {importedObjects.length > 0 && (
              <>
                <div className="bg-green-100 text-green-800 px-4 py-2 rounded-md mb-4">
                  <p className="font-bold">Import Validation Success</p>
                  <p>
                    Successfully validated import file with{" "}
                    <span className="font-medium">
                      0 Import Validation Errors
                    </span>{" "}
                    &{" "}
                    <span className="font-medium">
                      0 Import Validation Warnings
                    </span>
                    .
                  </p>
                </div>
                {importedObjects.map((item, index) => (
                  <div key={index} className="space-y-4">
                    <div className="border rounded-md p-4 bg-white">
                      <p className="font-bold mb-2">{item?.entity.language}</p>
                      <div className="space-y-1">
                        <div className="flex justify-between">
                          <span className="font-medium">Language:</span>
                          <span>{item?.entity.language}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="font-medium">Key:</span>
                          <span>{item?.entity.key}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="font-medium">Text:</span>
                          <span>{item?.entity.text}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Import;
