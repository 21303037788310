import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdFileUpload } from "react-icons/md";

export default function FileUpload({ accept, onChange }) {
  const [file, setFile] = useState(null);
  useEffect(() => {
    if(file && accept)
    setFile(null);
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const isFileValid =
        selectedFile &&
        (selectedFile.type.match(accept) || selectedFile.name.endsWith(accept));

      if (isFileValid) {
        setFile(selectedFile);
        onChange(selectedFile);
      } else {
        alert(
          `Invalid file type. Please select a file with ${accept} extension.`
        );
      }
    },
  });

  const handleRemoveFile = () => {
    setFile(null);
    onChange(null);
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div
        {...getRootProps()}
        className={`w-96 h-40 flex flex-col items-center justify-center border-2 border-dashed 
        rounded-2xl p-5 cursor-pointer transition-all ${
          isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
        }`}
      >
        <input {...getInputProps()} />
        <MdFileUpload className="text-gray-400" />
        <p className="text-gray-600">
          {file ? (
            <span className="text-sm text-gray-800">{file.name}</span>
          ) : isDragActive ? (
            "Drop the file here..."
          ) : (
            "Drag & drop or click to select"
          )}
        </p>
        {file && (
          <button
            type="button"
            onClick={handleRemoveFile}
            className="text-sm text-red-500"
          >
            Remove File
          </button>
        )}
      </div>
    </div>
  );
}
