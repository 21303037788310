import React, { useState, useEffect } from "react";
import { getQuestionsDataService } from "./BusinessRules";
import { MenuItem, Select } from "@mui/material";

const FieldRuleComp = ({
  modelName,
  data,
  setLoading,
  setFieldValue,
  setAnchorEl,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [questionnaireId, setQuestionnaireId] = useState("");
  const [questionnaireNewId, setQuestionnaireNewId] = useState(null);
  const [questionId, setQuestionId] = useState("");
  const [choiceId, setChoiceId] = useState("");
  const [selectTime, setSelectTime] = useState("");
  const [choicesData, setChoicesData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (questionnaireNewId !== "" && questionnaireNewId !== null) {
      getQuestionsData(questionnaireNewId);
    }
  }, [questionnaireNewId]);

  useEffect(() => {
    if (isSelected) {
      if (selectedValue && !selectTime && !questionnaireId) {
        setFieldValue(selectedValue);
      }
      if (selectTime && !selectedValue && !questionnaireId) {
        setFieldValue(selectTime);
      }
      if (questionnaireId && !selectedValue && !selectTime) {
        setFieldValue(questionnaireId);
      }
      setAnchorEl(null);
    }
    setTimeout(() => {
      setIsSelected(false);
    }, 100);
  }, [selectedValue, selectTime, questionnaireId, isSelected]);

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectTime(e.target.value);
  };

  const getQuestionsData = async (id) => {
    try {
      const res = await getQuestionsDataService(id, true);
      if (res?.status) {
        setQuestionsData(res?.data?.result);
        setChoicesData(res?.data?.result[0]?.choices);
      }
    } catch (err) {
      setLoading(false);
      console.log("Error: ", err?.message);
    }
  };

  const renderFormField = () => {
    switch (modelName.toUpperCase()) {
      case "VISITID":
      case "VISITHASREQUIREDDISPENSATION":
      case "VISITHASNOTREQUIREDDISPENSATION":
      case "PATIENTHASTAKENVISITDRUGS":
      case "LASTACTIVATEDPATIENTVISIT":
      case "LASTCOMPLETEDPATIENTVISIT":
      case "LASTACTIVATEDPATIENTVISITISNOT":
        return (
          <div className="form-group">
            <label htmlFor="VisitId">Visit:</label>
            <Select
              id="VisitId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.visits.map((visit) => (
                <MenuItem key={visit.id} value={visit.id}>
                  {visit.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "QUESTIONNAIREID":
        return (
          <div className="form-group">
            <label htmlFor="QuestionnaireId">Questionnaire:</label>
            <Select
              id="QuestionnaireId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.questionnaires.map((q) => (
                <MenuItem key={q.id} value={q.internalName}>
                  {q.internalName}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "QUESTIONANSWERCHOICE":
        return (
          <>
            <div className="form-group">
              <label htmlFor="QuestionnaireId">Questionnaire:</label>
              <Select
                id="QuestionnaireId"
                className="form-control"
                onChange={(e) => {
                  setQuestionnaireNewId(e.target.value);
                  let item = data?.questionnaires?.find(
                    (item) => item?.id === e.target.value
                  );
                  setQuestionnaireId(item?.internalName);
                }}
              >
                {data.questionnaires.map((q) => (
                  <MenuItem key={q.id} value={q.id}>
                    {q.internalName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="QuestionId">Question:</label>
              <Select
                id="QuestionId"
                className="form-control"
                onChange={(e) => setQuestionId(e.target.value)}
              >
                {questionsData &&
                  questionsData?.map((item) => (
                    <MenuItem key={item?.id} value={item?.exportDisplayName}>
                      {item?.exportDisplayName}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="ChoiceId">Choice:</label>
              <Select
                id="ChoiceId"
                className="form-control"
                onChange={(e) => setChoiceId(e.target.value)}
              >
                {choicesData &&
                  choicesData?.map((item) => (
                    <MenuItem key={item?.id} value={item?.optionTranslationKey}>
                      {item?.optionTranslationKey}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </>
        );
      case "PATIENTSTATUS":
        return (
          <div className="form-group">
            <label htmlFor="PatientStatusTypeId">Status:</label>
            <Select
              id="PatientStatusTypeId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.patientStatusTypes.map((status) => (
                <MenuItem
                  key={status.id}
                  value={status.patientStatusDescription}
                >
                  {status.patientStatusDescription}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "PATIENTATTRIBUTE":
        return (
          <div className="form-group">
            <label htmlFor="PatientAttribute">Patient Attribute:</label>
            <Select
              id="PatientAttribute"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.patientAttributeConfigurationDetails.map((attribute) => (
                <MenuItem key={attribute.id} value={attribute.description}>
                  {attribute.description}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "CONFIGUREDRULE":
        return (
          <div className="form-group">
            <label htmlFor="BusinessRuleId">Business Rule:</label>
            <Select
              id="BusinessRuleId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.businessRules.map((rule) => (
                <MenuItem key={rule.id} value={rule.name}>
                  {rule.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "HASMETSITEALERTCAP":
      case "HASMETSITESTOPCAP":
        return (
          <div className="form-group">
            <label htmlFor="CapId">Site Caps:</label>
            <Select
              id="CapId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.caps.map((cap) => (
                <MenuItem key={cap.id} value={cap.name}>
                  {cap.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      case "CHECKTIME":
        return (
          <div className="form-group">
            <label htmlFor="SelectTime">Please select a time (24 hours):</label>
            <input
              type="text"
              name="SelectTime"
              id="SelectTime"
              value={selectTime}
              className="form-control h-[30px]"
              onChange={handleTimeChange}
            />
          </div>
        );
      case "PATIENTISINCOUNTRY":
      case "PATIENTISNOTINCOUNTRY":
      case "SITEISINCOUNTRY":
        return (
          <div className="form-group">
            <label htmlFor="CountryId">Country:</label>
            <Select
              id="CountryId"
              className="form-control"
              onChange={handleDropdownChange}
            >
              {data.countries.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      default:
        return <h5>Not yet available</h5>;
    }
  };

  return (
    <div
      style={{
        width: "75%",
        margin: "auto",
        padding: "10px",
        maxHeight: "50vh",
      }}
    >
      {renderFormField()}
      <div className="form-group">
        <button
          className="btn btn-block btn-primary"
          type="button"
          onClick={() => setIsSelected(true)}
        >
          Select Value
        </button>
      </div>
    </div>
  );
};

export default FieldRuleComp;
