import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import {
  editSubmitAction,
  getSubmitActionById,
  getTypeNamesDataService,
} from "../../../../../services/submit_action";

import "../../StudySetup.css";
import { selectStyles } from "./CreateActions";

const EditActions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.state.id;

  const [load, setLoad] = useState(false);
  const [typeNamesData, setTypeNamesData] = useState([]);
  const [typeName, setTypeName] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const notify = () =>
    toast.success("Submit Action Edited Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const editActionSchema = yup.object().shape({
    displayName: yup.string().required("This field is required"),
    typeName: yup.string().required("This field is required"),
    successPatientStatusTypeId: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
    failurePatientStatusTypeId: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editActionSchema),
  });
  useEffect(() => {
    getTypeNames();
  }, []);

  useEffect(() => {
    if (id) fetchSubmitActionById(id);
  }, [id]);

  const getTypeNames = async () => {
    setLoad(true);
    try {
      const res = await getTypeNamesDataService();
      if (res?.status) {
        setLoad(false);
        setTypeNamesData(res?.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const fetchSubmitActionById = async (id) => {
    setLoad(true);
    try {
      const res = await getSubmitActionById(id);
      if (res.status) {
        setLoad(false);
        setTypeName(res?.data?.result?.typeName);
        const {
          displayName,
          typeName,
          failurePatientStatusTypeId,
          successPatientStatusTypeId,
        } = res.data?.result;
        reset({
          displayName,
          typeName,
          failurePatientStatusTypeId,
          successPatientStatusTypeId,
        });
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      const {
        displayName,
        typeName,
        successPatientStatusTypeId,
        failurePatientStatusTypeId,
      } = data;

      const newData = {
        id,
        displayName,
        typeName,
        successPatientStatusTypeId,
        failurePatientStatusTypeId,
      };

      const res = await editSubmitAction(id, newData);

      if (res.status) {
        setLoad(false);
        notify();
        navigate("/study-management/submit-actions");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isDirty) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/submit-actions");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/submit-actions");
    }
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
    padding: "11px 14px"
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <Link to="/study-management">Manage Your Study</Link> |{" "}
              <Link to="/study-management/submit-actions">Submit Actions</Link>{" "}
              | <p className="study-edit-link">Edit Submit Actions</p> -
              <b style={{ color: "gray" }}>
                {sessionStorage?.getItem("studyName")}
              </b>
            </p>
            <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Col md={6}>
                <div className="study-management-head-start">
                  <p className="study-management-heading">
                    Edit Submit Actions
                  </p>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box
              component="form"
              sx={{
                height: "auto",
                width: "100%",
              }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <Row>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Display Name {/* {errors.displayName?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                    <></>
                  )} */}
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("displayName", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.displayName && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.displayName.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Type Name {/* {errors.typeName?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                    <></>
                  )} */}
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="typeName"
                      // defaultValue={""}
                      value={typeName}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                        className: "custom-input-class",
                      }}
                      {...register("typeName", {
                        onChange: (e) => {
                          setValue("typeName", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                          setTypeName(e.target.value);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {typeNamesData &&
                        typeNamesData?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.typeName && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.typeName.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Success Patient Status TypeId{" "}
                    {/* {errors.successPatientStatusTypeId?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                    <></>
                  )} */}
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("successPatientStatusTypeId", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.successPatientStatusTypeId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.successPatientStatusTypeId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Failure Patient Status TypeId{" "}
                    {/* {errors.failurePatientStatusTypeId?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                    <></>
                  )} */}
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("failurePatientStatusTypeId", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.failurePatientStatusTypeId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.failurePatientStatusTypeId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={8}></Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    {/* <Link to="/study-management-builder"> */}
                    <button
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      className="study-management-cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    {/* <Link to="/study-management-builder"> */}
                    <button
                      type="submit"
                      className="study-management-create-btn-md"
                    >
                      Update
                    </button>
                  </div>
                </Col>
              </Row>

              {/* 
              <div className="createVisitButtonBody">
                <button
                  className="createVisitCancelBtn"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="createVisitSaveBtn">
                  Save
                </button>
              </div> */}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default EditActions;
