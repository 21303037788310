import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const schema = yup.object().shape({
  PatientNumberIncludeSiteId: yup.string().required("This field is required"),
  PatientNumberLength: yup
    .number()
    .required("This field is required")
    .positive()
    .integer(),
  PatientNumberPrefix: yup.string().required("This field is required"),
  PatientNumberPrefixSiteSeperator: yup
    .string()
    .required("This field is required"),
  PatientNumberSiteSubjectNumberSeperator: yup
    .string()
    .required("This field is required"),
});

const SubjectConfiguration = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { singleAttributeConfig, updateAttributeConfig } =
    useSubjectAttributesDetails();
  const { data } = singleAttributeConfig;
  const navigate = useNavigate();

  const getValueByKey = (key) => {
    const attribute = data?.find((item) => item.key === key);
    return attribute ? attribute.value : "";
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      PatientNumberIncludeSiteId: getValueByKey("PatientNumberIncludeSiteId"),
      PatientNumberLength: getValueByKey("PatientNumberLength"),
      PatientNumberPrefix: getValueByKey("PatientNumberPrefix"),
      PatientNumberPrefixSiteSeperator: getValueByKey(
        "PatientNumberPrefixSiteSeperator"
      ),
      PatientNumberSiteSubjectNumberSeperator: getValueByKey(
        "PatientNumberSiteSubjectNumberSeperator"
      ),
    },
  });

  const onSubmit = async (formData) => {
    setShowDialog(false);
    try {
      // Update attributes
      await Promise.all(
        Object.keys(formData).map((key) =>
          updateAttributeConfig(key, formData[key])
        )
      );
      // Show success toast
      toast.success("Configuration updated successfully!");
    } catch (error) {
      // Show error toast
      toast.error("Failed to update configuration.");
    }
  };

  const handleCancel = () => {
    setOpenConfirmDialog(true);
  };

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmNavigation = () => {
    setOpenConfirmDialog(false);
    navigate("/study-management");
  };

  return (
    <div>
      <div className="studyManageBody">
        <div className="subjectConfiguration">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (isDirty) {
                setShowDialog(true);
              } else {
                toast.error("No changes made to save.", {
                  toastId: "no-changes",
                });
              }
            }}
          >
            <Row>
              <Col md={6}>
                <div className="subjectVariableBody">
                  <div className="subjectVariableBodyInfo">
                    <p className="smallHeading">
                      Patient Number Include Site Id
                    </p>
                  </div>
                  <div className="subjectVariableInputBody">
                    <Controller
                      name="PatientNumberIncludeSiteId"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: { fontSize: 15, width: "210px", padding: "10px 14px" },
                          }}
                          error={!!errors.PatientNumberIncludeSiteId}
                          helperText={
                            errors.PatientNumberIncludeSiteId?.message
                          }
                        />
                      )}
                    />
                    <button className="updateBlueBtn" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="subjectVariableBody">
                  <div className="subjectVariableBodyInfo">
                    <p className="smallHeading">Patient Number Length</p>
                  </div>
                  <div className="subjectVariableInputBody">
                    <Controller
                      name="PatientNumberLength"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: { fontSize: 15, width: "210px" , padding: "10px 14px"},
                          }}
                          error={!!errors.PatientNumberLength}
                          helperText={errors.PatientNumberLength?.message}
                        />
                      )}
                    />
                    <button className="updateBlueBtn" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="customDivider"></div>
            <Row>
              <Col md={6}>
                <div className="subjectVariableBody">
                  <div className="subjectVariableBodyInfo">
                    <p className="smallHeading">Patient Number Prefix</p>
                  </div>
                  <div className="subjectVariableInputBody">
                    <Controller
                      name="PatientNumberPrefix"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: { fontSize: 15, width: "210px", padding: "10px 14px" },
                          }}
                          error={!!errors.PatientNumberPrefix}
                          helperText={errors.PatientNumberPrefix?.message}
                        />
                      )}
                    />
                    <button className="updateBlueBtn" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="subjectVariableBody">
                  <div className="subjectVariableBodyInfo">
                    <p className="smallHeading">
                      Patient Number Prefix Site Seperator
                    </p>
                  </div>
                  <div className="subjectVariableInputBody">
                    <Controller
                      name="PatientNumberPrefixSiteSeperator"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: { fontSize: 15, width: "210px", padding: "10px 14px" },
                          }}
                          error={!!errors.PatientNumberPrefixSiteSeperator}
                          helperText={
                            errors.PatientNumberPrefixSiteSeperator?.message
                          }
                        />
                      )}
                    />
                    <button className="updateBlueBtn" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="customDivider"></div>
            <Row>
              <Col md={6}>
                <div className="subjectVariableBody">
                  <div className="subjectVariableBodyInfo">
                    <p className="smallHeading">
                      Patient Number Site Subject Number Seperator
                    </p>
                  </div>
                  <div className="subjectVariableInputBody">
                    <Controller
                      name="PatientNumberSiteSubjectNumberSeperator"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: { fontSize: 15, width: "210px", padding: "10px 14px" },
                          }}
                          error={
                            !!errors.PatientNumberSiteSubjectNumberSeperator
                          }
                          helperText={
                            errors.PatientNumberSiteSubjectNumberSeperator
                              ?.message
                          }
                        />
                      )}
                    />
                    <button className="updateBlueBtn" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6} className="text-end">
                <Button
                  onClick={handleCancel}
                  color="primary"
                  variant="contained"
                  className="!mt-3 !text-white !px-8 !py-5 !text-xl"
                >
                  Back to study
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle
          id="confirm-dialog-title"
          className="!text-2xl !font-semibold"
        >
          Confirm Submission
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-dialog-description"
            className="!text-xl"
          >
            Are you sure you want to submit the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="primary"
            className="!text-xl"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            autoFocus
            className="!text-xl"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="!text-2xl !font-semibold"
        >
          Confirm Navigation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="!text-xl">
            Are you sure you want to leave without saving changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            className="!text-xl"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmNavigation}
            color="primary"
            autoFocus
            className="!text-xl"
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubjectConfiguration;
