import { dataServer } from "./axios.config";
import axios from "axios";


export const getReportsServices = () => {
    return new Promise((resolve, reject) => {
      dataServer
      .get(`app/ReportLayout/GetAllReportsForDisplay`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  export const getLoadEditDataHeaderServices = (id) => {
    return new Promise((resolve, reject) => {
      dataServer
      .get(`/app/ReportLayout/LoadEditDataHeader?ReportId=${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  export const getLoadEditDataFooterServices = (id) => {
    return new Promise((resolve, reject) => {
      dataServer
      .get(`app/ReportLayout/LoadEditDataFooter?ReportId=${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  export const getLoadEditDataSignaturePageServices = (id) => {
    return new Promise((resolve, reject) => {
      dataServer
      .get(`app/ReportLayout/LoadEditDataSignaturePage?ReportId=${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  export const SaveReportsLayout = (data) => {
    return new Promise((resolve, reject) => {
        dataServer
        .post(`app/ReportLayout/SaveLayout`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };