import React, { useCallback, useState } from "react";
import { Link, useNavigate, useLocation, useBlocker } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import DialogBox from "../../../../components/DialogBox";
import useDoseManagementDetails from "../../../../hooks/Api/useDoseManagement";
import { useDoseManagement } from "../../../../context/studyManagement/DoseManagement/DoseManagementContext";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import "../StudySetup.css";

// Validation schema
const editDoseLevelSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  isRecommended: yup.bool().oneOf([true, false], "This field is required"),
  isEnabled: yup.bool().oneOf([true, false], "This field is required"),
});

const CreateDoseLevels = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;
  const [load, setLoad] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const [formChanged, setFormChanged] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [blockedNavigation, setBlockedNavigation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { updateDoseMutation, fetchDoseLevelById } = useDoseManagementDetails();
  const { isLoading, data, refetch } = useDoseManagement();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(editDoseLevelSchema),
    defaultValues: {
      name: "",
      isRecommended: false,
      isEnabled: false,
    },
  });

  // const shouldBlock = useCallback(
  //   ({ currentLocation, nextLocation }) => {
  //     if (isDirty && !submitting && !blockedNavigation) {
  //       // Check submitting state
  //       setBlockedNavigation(true);
  //       setPendingNavigation(() => () => navigate(nextLocation.pathname));
  //       setOpenConfirmDialog(true);
  //       return true;
  //     }
  //     return false;
  //   },
  //   [isDirty, navigate, submitting, blockedNavigation]
  // );

  // useBlocker(shouldBlock, isDirty);

  const notify = () =>
    toast.success("Dosage Level Edited Successfully", {
      toastId: "form-creation",
    });
  const requestFailed = (msg) =>
    toast.error(`${msg}`, { toastId: "requestFailed" });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDoseLevelById(id);
        if (data) {
          const initialData = {
            name: data.name || "",
            isEnabled: data.isEnabled || false,
            isRecommended: data.isRecommended || false,
          };
          setInitialValues(initialData);
          reset(initialData);
        } else {
          console.error("No data received for dose level");
          throw new Error("No data received for dose level");
        }
      } catch (err) {
        console.log("Error: ", err.message);
        requestFailed(err.message);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, reset]);

  React.useEffect(() => {
    const subscription = getValues();
    if (
      subscription &&
      JSON.stringify(initialValues) !== JSON.stringify(subscription)
    ) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [getValues, initialValues]);

  // const onSubmit = async (data) => {
  //   setOpenConfirmDialog(true);
  // };

  const onSubmit = async () => {
    try {
      // setBlockedNavigation(false);
      setSubmitting(true);
      setBlockedNavigation(false);
      setLoad(true);
      setShowDialog(false);

      const newData = {
        name: getValues("name"),
        isEnabled: getValues("isEnabled"),
        isRecommended: getValues("isRecommended"),
      };

      const res = await updateDoseMutation.mutateAsync({
        id,
        updatedData: newData,
      });

      if (res?.data?.status === "200") {
        notify();
        navigate("/study-management/dose-levels");
      } else {
        throw new Error("Update failed");
      }
    } catch (err) {
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    } finally {
      setLoad(false);
      setSubmitting(false);
    }
  };

  // const handleConfirmNavigation = () => {
  //   setOpenConfirmDialog(false);
  //   navigate("/dose-levels");
  // };
  const handleConfirmNavigation = () => {
    setBlockedNavigation(false);
    setOpenConfirmDialog(false);
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
  };

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
    setBlockedNavigation(true);
    setPendingNavigation(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isDirty) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/dose-levels");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/dose-levels");
    }
    // setOpenConfirmDialog(true);
  };

  const labelStyles = { display: "flex", alignItems: "center", height: "35px" };
  const textBoxBody = { marginLeft: "10px" };
  const textBoxStyles = { fontSize: 15, width: "400px", height: "10px", padding: "11px 14px" };
  const rowSpacing = { marginTop: "2%" };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/dose-levels">Dosage Level</Link> |{" "}
            <p className="study-edit-link">Edit Dosage</p> -
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Edit Dosage Level</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <Box
            component="form"
            // onSubmit={handleSubmit(onSubmit)}
            onSubmit={(e) => {
              e.preventDefault();
              if (isDirty) {
                setShowDialog(true);
              } else {
                toast.error("No changes made to save.", {
                  toastId: "no-changes",
                });
              }
            }}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Name
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={10}>
                <TextField
                  style={textBoxBody}
                  inputProps={{ style: textBoxStyles }}
                  {...register("name")}
                />
                {errors.name && (
                  <div className="createCaregiverFlexEnd">
                    <div className="createCaregiverError">
                      <span className="error-text">{errors.name.message}</span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row style={rowSpacing}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Is Recommended{" "}
                  {errors.isRecommended?.message && (
                    <span className="error-highlight">*</span>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <Controller
                  name="isRecommended"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                  )}
                />
                {errors.isRecommended && (
                  <p className="error-text">{errors.isRecommended.message}</p>
                )}
              </Col>
            </Row>
            <Row style={rowSpacing}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Is Enabled{" "}
                  {errors.isEnabled?.message && (
                    <span className="error-highlight">*</span>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <Controller
                  name="isEnabled"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                  )}
                />
                {errors.isEnabled && (
                  <p className="error-text">{errors.isEnabled.message}</p>
                )}
              </Col>
            </Row>

            <Row style={rowSpacing}>
              <Col md={8}></Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="button"
                    onClick={(e) => handleCancel(e)}
                    className="study-management-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="submit"
                    className="study-management-create-btn-md"
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
      )}

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle
          id="confirm-dialog-title"
          className="!text-2xl !font-semibold"
        >
          Confirm Submission
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-dialog-description"
            className="!text-xl"
          >
            Are you sure you want to submit the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="primary"
            className="!text-xl"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            autoFocus
            className="!text-xl"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={openConfirmDialog}
        onClose={handleDialogClose}
        aria-labelledby="confirm-leave-dialog-title"
        aria-describedby="confirm-leave-dialog-description"
      >
        <DialogTitle
          id="confirm-leave-dialog-title"
          className="!text-2xl !font-semibold"
        >
          Unsaved Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-leave-dialog-description"
            className="!text-xl"
          >
            You have unsaved changes. Are you sure you want to leave this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            className="!text-xl"
          >
            Stay
          </Button>
          <Button
            onClick={handleConfirmNavigation}
            color="primary"
            autoFocus
            className="!text-xl"
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default CreateDoseLevels;
