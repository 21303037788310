import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  TextField,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaTrashAlt } from "react-icons/fa";

import {
  editWidget,
  getWidgetById,
  getAllWidgetTypes,
  addSystemAction,
  addWidgetCount,
  removeSystemPermission,
  removeWidgetCounts,
} from "../../../../../services/widgets";
import DeleteModal from "../../../../../components/DeleteModal";

const EditWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.state.id;

  const [load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({
    id: null,
    type: "",
  });

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [widgetAllEditData, setWidgetAllEditData] = React.useState();

  const editWidgetSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    translationTitleText: yup.string().required("This field is required"),
    translationButtonText: yup.string().required("This field is required"),
    widgetTypeId: yup.string().required("This field is required"),
    iconName: yup.string().required("This field is required"),
    columnWidth: yup.string().required("This field is required"),
    columnHeight: yup.string().required("This field is required"),
    controllerAction: yup.string().required("This field is required"),
    controllerName: yup.string().required("This field is required"),
    sequence: yup.string().required("This field is required"),
  });

  const editSystemActionSchema = yup.object().shape({
    systemAction: yup.string().required("This field is required"),
  });

  const editWidgetCountSchema = yup.object().shape({
    functionName: yup.string().required("This field is required"),
    databaseTableName: yup.string().required("This field is required"),
    translationTitleText: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editWidgetSchema),
  });

  const {
    register: registerPermissions,
    handleSubmit: handleSubmitPermissions,
    reset: resetPermissions,
    getValues: getValuesPermissions,
    setValue: setValuePermissions,
    formState: { errors: errorsPermissions },
  } = useForm({
    resolver: yupResolver(editSystemActionSchema),
  });

  const {
    register: registerWidgetCount,
    handleSubmit: handleSubmitWidgetCount,
    reset: resetWidgetCount,
    getValues: getValuesWidgetCount,
    setValue: setValueWidgetCount,
    formState: { errors: errorsWidgetCount },
  } = useForm({
    resolver: yupResolver(editWidgetCountSchema),
  });

  React.useEffect(() => {
    if (
      widgetAllEditData?.widgetRecord &&
      widgetAllEditData?.widgetTypes?.length > 0
    ) {
      console.log(
        "Setting widgetTypeId:",
        widgetAllEditData.widgetRecord.widgetTypeId
      );
      const isValid = widgetAllEditData.widgetTypes.some(
        (item) => item.id === widgetAllEditData.widgetRecord.widgetTypeId
      );

      if (isValid) {
        setValue("widgetTypeId", widgetAllEditData.widgetRecord.widgetTypeId, {
          shouldValidate: true,
        });
      }
      setValue("name", widgetAllEditData?.widgetRecord.name, {
        shouldValidate: true,
      });
      setValue("iconName", widgetAllEditData?.widgetRecord.iconName, {
        shouldValidate: true,
      });
      setValue(
        "translationButtonText",
        widgetAllEditData?.widgetRecord.translationButtonText,
        {
          shouldValidate: true,
        }
      );
      setValue("columnHeight", widgetAllEditData?.widgetRecord.columnHeight, {
        shouldValidate: true,
      });
      setValue("columnWidth", widgetAllEditData?.widgetRecord.columnWidth, {
        shouldValidate: true,
      });
      setValue(
        "controllerName",
        widgetAllEditData?.widgetRecord.controllerName,
        {
          shouldValidate: true,
        }
      );
      setValue(
        "controllerAction",
        widgetAllEditData?.widgetRecord.controllerActionName,
        {
          shouldValidate: true,
        }
      );
      setValue("sequence", widgetAllEditData?.widgetRecord.widgetPosition, {
        shouldValidate: true,
      });
      setValue(
        "translationTitleText",
        widgetAllEditData?.widgetRecord.translationTitleText,
        {
          shouldValidate: true,
        }
      );
    }
  }, [widgetAllEditData]);

  const notify = (msg) => {
    console.log(msg, "111");
    toast.success(msg, {
      // theme: "colored",
      toastId: "form-creation",
    });
  };

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      const res = await editWidget(id, data, widgetAllEditData);
      if (res.status) {
        setLoad(false);
        notify(res?.data?.message);
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const onSubmitPermissions = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      const payload = {
        systemActionId: data.systemAction,
        widgetId: id,
      };
      const res = await addSystemAction(payload);
      if (res.status) {
        setLoad(false);
        notify(res?.data?.message);
        fetchWidgetById(id);
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const onSubmitWidgetsCount = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      const payload = {
        functionName: data.functionName,
        tableName: data.databaseTableName,
        translationText: data.translationTitleText,
        widgetId: id,
        text: data.translationTitleText,
      };
      const res = await addWidgetCount(payload);
      if (res.status) {
        setLoad(false);
        notify(res?.data?.message);
        fetchWidgetById(id);
        setValueWidgetCount("functionName", "");
        setValueWidgetCount("translationTitleText", "");
        setValueWidgetCount("databaseTableName", "");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const fetchWidgetById = async (id) => {
    try {
      const res = await getWidgetById(id);

      if (res.data) {
        const widgetsData = res?.data.result;

        setWidgetAllEditData(widgetsData);

        reset({
          name,
          columnHeight,
          columnWidth,
          iconName,
          permission,
          translationButtonText,
          translationDescriptionText,
          translationTitleText,
          widgetPosition,
          widgetType,
          widgetTypeId,
        });
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const handleRemove = async (actionId, type) => {
    try {
      setLoad(true);
      setShowDialog(false);

      let res;
      if (type === "systemActions") {
        res = await removeSystemPermission(actionId);
      } else if (type === "widgetCount") {
        res = await removeWidgetCounts(actionId);
      } else {
        throw new Error("Invalid type provided");
      }

      if (res?.status) {
        setLoad(false);
        setShowConfirmModal(false);
        notify(res?.data?.message);
        fetchWidgetById(id);
      } else {
        throw new Error("Failed to remove");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      setShowConfirmModal(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  React.useEffect(() => {
    fetchWidgetById(id);
  }, [id]);

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/study-settings/widgets");
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 12,
    width: "270px",
    height: "5px",
  };

  const selectBoxStyles = {
    fontSize: 12,
    width: "390px",
    height: "2px",
  };

  const selectStyles = {
    width: 300,
    marginLeft: 0.3,
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <Link to="/study-management">Manage Your Study</Link> |{" "}
              <Link to="/study-management/study-settings/widgets">Widgets</Link>{" "}
              | <p className="study-edit-link">Edit Widget</p>
            </p>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Row>
              <Col md={4}>
                <p className="languages-heading-md border-b ">Edit Widget</p>

                <div className="languages-form-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={4}>
                        <div className="flex items-center justify-left gap-[50px] mt-1">
                          <div className="language-label-body">
                            <label>
                              Widget Name
                              <span className="error-highlight">*</span>
                            </label>
                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("name", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.name && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.name.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Widget Type{" "}
                              <span className="error-highlight">*</span>
                            </label>

                            <FormControl sx={selectStyles}>
                              <Select
                                name="widgetTypeId"
                                value={getValues("widgetTypeId") || ""}
                                inputProps={{
                                  style: selectBoxStyles,
                                  "aria-label": "Without label",
                                  className: "custom-Widget-class",
                                }}
                                {...register("widgetTypeId", {
                                  onChange: (e) => {
                                    setValue("widgetTypeId", e.target.value, {
                                      shouldValidate: true,
                                    });
                                    setShowDialog(true);
                                  },
                                })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {widgetAllEditData?.widgetTypes.map(
                                  (item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              {errors.widgetTypeId && (
                                <>
                                  <div className="createCaregiverFlexEnd">
                                    <div className="createCaregiverError">
                                      <span className="error-text">
                                        {errors.widgetTypeId.message}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </FormControl>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Title
                              {/* {errors.translationTitleText?.message ? ( */}
                              <span className="error-highlight">*</span>
                              {/* ) : (
                                <></>
                            )} */}
                            </label>

                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("translationTitleText", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.translationTitleText && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.translationTitleText.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Button Text{" "}
                              <span className="error-highlight">*</span>
                            </label>
                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("translationButtonText", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.translationButtonText && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.translationButtonText.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Controller Name{" "}
                              <span className="error-highlight">*</span>
                            </label>

                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("controllerName", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.controllerName && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.controllerName.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Controller Action{" "}
                              <span className="error-highlight">*</span>
                            </label>

                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("controllerAction", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.controllerAction && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.controllerAction.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Sequence{" "}
                              <span className="error-highlight">*</span>
                            </label>
                            <FormControl sx={selectStyles}>
                              <Select
                                name="sequence"
                                value={getValues("sequence") || ""}
                                inputProps={{
                                  style: selectBoxStyles,
                                  "aria-label": "Without label",
                                  className: "custom-Widget-class",
                                }}
                                {...register("sequence", {
                                  onChange: (e) => {
                                    setValue("sequence", e.target.value, {
                                      shouldValidate: true,
                                    });
                                    setShowDialog(true);
                                  },
                                })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {Array.from({ length: 50 }, (_, index) => (
                                  <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.sequence && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.sequence.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Icon Name {/* {errors.iconName?.message ? ( */}
                              <span className="error-highlight">*</span>
                              {/* ) : (
                                <></>
                            )} */}
                            </label>

                            <TextField
                              style={textBoxBody}
                              inputProps={{
                                style: textBoxStyles,
                              }}
                              {...register("iconName", {
                                onChange: (e) => {
                                  setShowDialog(true);
                                },
                              })}
                            />
                            {errors.iconName && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.iconName.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Column Width{" "}
                              <span className="error-highlight">*</span>
                            </label>

                            <FormControl sx={selectStyles}>
                              <Select
                                name="columnWidth"
                                value={getValues("columnWidth") || ""}
                                inputProps={{
                                  style: selectBoxStyles,
                                  "aria-label": "Without label",
                                  className: "custom-Widget-class",
                                }}
                                {...register("columnWidth", {
                                  onChange: (e) => {
                                    setValue("columnWidth", e.target.value, {
                                      shouldValidate: true,
                                    });
                                    setShowDialog(true);
                                  },
                                })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {Array.from({ length: 6 }, (_, index) => (
                                  <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.columnWidth && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.columnWidth.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="language-flexbox">
                          <div className="language-label-body">
                            <label>
                              Column Height{" "}
                              <span className="error-highlight">*</span>
                            </label>

                            <FormControl sx={selectStyles}>
                              <Select
                                name="columnHeight"
                                value={getValues("columnHeight") || ""}
                                inputProps={{
                                  style: selectBoxStyles,
                                  "aria-label": "Without label",
                                  className: "custom-Widget-class",
                                }}
                                {...register("columnHeight", {
                                  onChange: (e) => {
                                    setValue("columnHeight", e.target.value, {
                                      shouldValidate: true,
                                    });
                                    setShowDialog(true);
                                  },
                                })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {Array.from({ length: 4 }, (_, index) => (
                                  <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.columnHeight && (
                              <>
                                <div className="createCaregiverFlexEnd">
                                  <div className="createCaregiverError">
                                    <span className="error-text">
                                      {errors.columnHeight.message}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <button
                      type="submit"
                      className="w-40 h-12 border-none bg-gradient-to-r from-[#3661eb] to-[#3661eb] rounded text-white font-poppins font-medium text-md capitalize mt-5 ml-2"
                    >
                      Submit
                    </button>
                    <button
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      className="w-40 h-12 bg-transparent border-2 border-transparent rounded text-[#3661eb] font-poppins font-medium text-md capitalize mt-5 ml-2 border-[#3661eb]"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </Col>

              <Col md={4}>
                <p className="languages-heading-md border-b ">Widget Counts</p>
                {widgetAllEditData?.widgetRecord?.widgetCounts?.length > 0 && (
                  <table className="w-full table-auto border-collapse border border-gray-200 mb-2">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 border border-gray-300 text-left text-sm">
                          Function Name
                        </th>
                        <th className="px-4 py-2 border border-gray-300 text-left text-sm">
                          Table Name
                        </th>
                        <th className="px-4 py-2 border border-gray-300 text-left text-sm">
                          Name
                        </th>
                        <th className="px-4 py-2 border border-gray-300 text-left text-sm">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {widgetAllEditData?.widgetRecord?.widgetCounts.map(
                        (item) => (
                          <tr
                            key={item.id}
                            className="border-b border-gray-200 wrap_tab"
                          >
                            <td className="px-4 py-2">{item.functionName}</td>
                            <td className="px-4 py-2">{item.tableName}</td>
                            <td className="px-4 py-2">{item.text}</td>

                            <td className="px-4 py-2 text-right">
                              <button
                                onClick={() => {
                                  setSelectedItem({
                                    id: item.id,
                                    type: "widgetCount",
                                  });
                                  setShowConfirmModal(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  background: "red",
                                  border: "none",
                                  borderRadius: "5px",
                                  padding: "7px 10px",
                                }}
                              >
                                <FaTrashAlt
                                  color="white"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}

                <form onSubmit={handleSubmitWidgetCount(onSubmitWidgetsCount)}>
                  <div className="flex items-center justify-left gap-[50px] mt-1">
                    <div className="text-[12px] text-[#4f4f4f]">
                      <label>
                        Function Name:{" "}
                        <span className="error-highlight">*</span>
                      </label>

                      <FormControl sx={{ width: 350 }}>
                        <Select
                          name="functionName"
                          value={getValuesWidgetCount("functionName")}
                          disabled={
                            getValues("widgetTypeId") !=
                            "bb7ae916-3a91-4cf6-abd2-775e57145263"
                          }
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "#f2f2f2",
                              color: "#9e9e9e",
                              cursor: "not-allowed",
                            },
                          }}
                          inputProps={{
                            style: selectBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...registerWidgetCount("functionName", {
                            onChange: (e) => {
                              setValueWidgetCount(
                                "functionName",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {widgetAllEditData?.widgetFunctionNames.map(
                            (item, index) => (
                              <MenuItem key={index} value={item.key}>
                                {item.value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {errorsWidgetCount.functionName && (
                          <>
                            <div className="createCaregiverFlexEnd">
                              <div className="createCaregiverError">
                                <span className="error-text">
                                  {errorsWidgetCount.functionName.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className="language-flexbox">
                    <div className="text-[12px] text-[#4f4f4f]">
                      <label>
                        Database Table Name:{" "}
                        <span className="error-highlight">*</span>
                      </label>

                      <FormControl sx={{ width: 350 }}>
                        <Select
                          name="databaseTableName"
                          value={getValuesWidgetCount("databaseTableName")}
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "#f2f2f2",
                              color: "#9e9e9e",
                              cursor: "not-allowed",
                            },
                          }}
                          disabled={
                            getValues("widgetTypeId") !=
                            "bb7ae916-3a91-4cf6-abd2-775e57145263"
                          }
                          inputProps={{
                            style: selectBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...registerWidgetCount("databaseTableName", {
                            onChange: (e) => {
                              setValueWidgetCount(
                                "databaseTableName",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {widgetAllEditData?.tableNames.map((item, index) => (
                            <MenuItem key={index} value={item.key}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                        {errorsWidgetCount.databaseTableName && (
                          <>
                            <div className="createCaregiverFlexEnd">
                              <div className="createCaregiverError">
                                <span className="error-text">
                                  {errorsWidgetCount.databaseTableName.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className="language-flexbox">
                    <div className="text-[12px] text-[#4f4f4f]">
                      <label>
                        Count Display Text:
                        <br />
                        This will be the text that displays next to the count.
                        <span className="error-highlight">*</span>
                      </label>
                      <TextField
                        style={textBoxBody}
                        disabled={
                          getValues("widgetTypeId") !=
                          "bb7ae916-3a91-4cf6-abd2-775e57145263"
                        }
                        sx={{
                          "& .MuiInputBase-root.Mui-disabled": {
                            backgroundColor: "#f2f2f2",
                            color: "#9e9e9e",
                            cursor: "not-allowed",
                          },
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#9e9e9e",
                          },
                        }}
                        inputProps={{
                          style: {
                            fontSize: 12,
                            width: "320px",
                            height: "5px",
                          },
                        }}
                        {...registerWidgetCount("translationTitleText", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                      {errorsWidgetCount.translationTitleText && (
                        <>
                          <span className="text-red-500 text-[12px] mt-1 ml-[10px]">
                            {errorsWidgetCount.translationTitleText.message}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    disabled={
                      getValues("widgetTypeId") !=
                      "bb7ae916-3a91-4cf6-abd2-775e57145263"
                    }
                    className="absolute w-20 h-12 border-none bg-gradient-to-r from-[#3661eb] to-[#3661eb] rounded text-white font-poppins font-medium text-md capitalize mt-5 ml-2"
                  >
                    Add
                  </button>
                </form>
              </Col>

              <Col md={4}>
                <p className="languages-heading-md border-b ">Permissions</p>
                {widgetAllEditData?.widgetRecord.widgetSystemActions?.length >
                  0 && (
                  <table className="w-full table-auto border-collapse border border-gray-200 mb-2">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 border border-gray-300 text-left text-sm">
                          Name
                        </th>
                        <th className="px-4 py-2 border border-gray-300 text-sm">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {widgetAllEditData?.widgetRecord.widgetSystemActions.map(
                        (item) => (
                          <tr
                            key={item.id}
                            className="border-b border-gray-200"
                          >
                            <td className="px-4 py-2 text-sm">
                              {item?.systemAction.name}
                            </td>

                            <td className="px-4 py-2">
                              <button
                                onClick={() => {
                                  setSelectedItem({
                                    id: item.id,
                                    type: "systemActions",
                                  });
                                  setShowConfirmModal(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  background: "red",
                                  border: "none",
                                  borderRadius: "5px",
                                  padding: "7px 10px",
                                }}
                              >
                                <FaTrashAlt
                                  color="white"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
                <form onSubmit={handleSubmitPermissions(onSubmitPermissions)}>
                  <div className="flex items-center justify-left gap-[50px] mt-1">
                    <div className="text-[12px] text-[#4f4f4f]">
                      <label>
                        System Action (Permission):
                        <span className="error-highlight">*</span>
                      </label>
                      <FormControl sx={{ width: 350 }}>
                        <Select
                          name="systemAction"
                          value={getValuesPermissions("systemAction")}
                          inputProps={{
                            style: selectBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...registerPermissions("systemAction", {
                            onChange: (e) => {
                              setValuePermissions(
                                "systemAction",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {widgetAllEditData?.systemActions.map(
                            (item, index) => (
                              <MenuItem key={index} value={item.key}>
                                {item.value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {errorsPermissions.systemAction && (
                          <>
                            <div className="createCaregiverFlexEnd">
                              <div className="createCaregiverError">
                                <span className="error-text">
                                  {errorsPermissions.systemAction.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </FormControl>
                    </div>
                  </div>
                  <button className="absolute w-20 h-12 border-none bg-gradient-to-r from-[#3661eb] to-[#3661eb] rounded text-white font-poppins font-medium text-md capitalize mt-5 ml-2">
                    Add
                  </button>
                </form>
              </Col>
            </Row>
          </div>
          {showConfirmModal && (
            <DeleteModal
              open={showConfirmModal}
              handleClose={() => setShowConfirmModal(false)}
              handleDelete={() =>
                handleRemove(selectedItem.id, selectedItem.type)
              }
              subject={selectedItem.type}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditWidget;
