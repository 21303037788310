import { studyServer } from "./study_axios"

export const getAllWidgets = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Widget/GetWidgets")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllWidgetTypes = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/Widgets/widget-types")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getWidgetById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Widget/EditOnLoadData?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewWidget = (data) => {
  //   const formData = new FormData();
  //   for (let value in data) {
  //     formData.append(value, data[value]);
  //   }
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/Widget/AddWidgetCount`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editWidget = (id, data, widgetAllEditData) => {
  const payload = {
    "id": id,
    "name": data.name,
    "translationTitleText": data.translationTitleText,
    "translationDescriptionText": "string",
    "translationButtonText": data.translationButtonText,
    "controllerName": data.controllerName,
    "controllerActionName": data.controllerAction,
    "widgetTypeId": data.widgetTypeId,
    "widgetPosition": parseInt(data.sequence),
    "iconName": data.iconName,
    "columnWidth": parseInt(data.columnWidth),
    "columnHeight": parseInt(data.columnHeight),
    "reportId": widgetAllEditData?.widgetRecord?.reportId || null,
  }
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Widget/UpdateWidget`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addSystemAction = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Widget/AddWidgetSystemAction`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteWidget = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .delete(`/Widgets/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addWidgetCount = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Widget/AddWidgetCount`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const removeSystemPermission = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Widget/RemoveWidgetSystemAction?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeWidgetCounts = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Widget/RemoveWidgetCount?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};