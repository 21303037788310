import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import { BiSolidReport } from "react-icons/bi";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";

import "../../StudySetup.css";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const ApproveQuarantine = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  
  // Sample Report Data
  const [drugCriteriaData, setDrugCriteriaData] = useState([
    { id: 1, description: "Report 1" },
    { id: 2, description: "Report 2" },
    { id: 3, description: "Report 3" },

  ]);


  const [allAttributesData, setAllAttributesData] = useState([
    { id: 1, country: "Item A", SiteDepotNumber: "Error A", Investigator: 5, BlindedLotNumber: 10, Expiry: 50, AvailableATDepot: 1, reportId: 1 },
    { id: 2, country: "Item B", SiteDepotNumber: "Error B", Investigator: 3, BlindedLotNumber: 15, Expiry: 100, AvailableATDepot: 2, reportId: 2 },
  ]);

  const [selectedReport, setSelectedReport] = useState(drugCriteriaData[0]?.id || null);
  const [attributesData, setAttributesData] = useState([]);

  useEffect(() => {
    if (selectedReport) {
      const filteredData = allAttributesData.filter((item) => item.reportId === selectedReport);
      setAttributesData(filteredData);
    }
  }, [selectedReport, allAttributesData]);

  const rows = attributesData.map((row) => ({
    id: row.id,
    country: row.country,
    SiteDepotNumber: row.SiteDepotNumber,
    Investigator: row.Investigator,
    BlindedLotNumber: row.BlindedLotNumber,
    Expiry: row.Expiry,
    AvailableATDepot: row.AvailableATDepot,
    removeId: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Country"}</div>,
      renderCell: (params) => {
        const country = params.row.country;

        return (
          <div className="grid-body">
            <p>{country}</p>
          </div>
        );
      },
    },
    {
      field: "SiteDepotNumber",
      headerName: "SiteDepotNumber",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"SiteDepotNumber"}</div>,
      renderCell: (params) => {
        const SiteDepotNumber = params.row.SiteDepotNumber;

        return (
          <div className="grid-body">
            <p>{SiteDepotNumber}</p>
          </div>
        );
      },
    },
    {
      field: "Investigator",
      headerName: "Investigator",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Investigator"}</div>,
      renderCell: (params) => {
        const Investigator = params.row.Investigator;

        return (
          <div className="grid-body">
            <p>{Investigator}</p>
          </div>
        );
      },
    },
    {
      field: "BlindedLotNumber",
      headerName: "BlindedLotNumber",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"BlindedLotNumber"}</div>,
      renderCell: (params) => {
        const BlindedLotNumber = params.row.BlindedLotNumber;

        return (
          <div className="grid-body">
            <p>{BlindedLotNumber}</p>
          </div>
        );
      },
    },
    {
      field: "Expiry",
      headerName: "Expiry",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Expiry"}</div>,
      renderCell: (params) => {
        const Expiry = params.row.Expiry;

        return (
          <div className="grid-body">
            <p>{Expiry}</p>
          </div>
        );
      },
    },
    {
      field: "AvailableATDepot",
      headerName: "AvailableATDepot",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"AvailableATDepot"}</div>,
      renderCell: (params) => {
        const AvailableATDepot = params.row.AvailableATDepot;

        return (
          <div className="grid-body">
            <p>{AvailableATDepot}</p>
          </div>
        );
      },
    },
    {
      field: "removeId",
      headerName: "Remove",
      width: 100,
      renderHeader: () => <div className="grid-heading">{"Remove"}</div>,
      renderCell: (params) => (
        <button
          onClick={() => {
            setAttributesData(attributesData.filter((item) => item.id !== params.value));
          }}
          style={{
            cursor: "pointer",
            background: "red",
            border: "none",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <FaTrashAlt color="white" style={{ fontSize: "15px" }} />
        </button>
      ),
    },
  ];

  return (
    <>
      {load ? (
        <div className="flex justify-center items-center h-70vh">
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <Box component="form" sx={{ height: "auto", width: "100%" }}>
            <div className="flex gap-6">
              <div className="w-1/4 border border-gray-300 rounded-md bg-white">
                <div className="bg-gray-200 p-1">
                  <h4 className="flex ml-1 font-[600]">
                    <BiSolidReport /> REPORTS
                  </h4>
                </div>
                <div className="border border-gray-300 rounded-md bg-white m-4">
                  {drugCriteriaData.map((item) => (
                    <div
                      key={item.id}
                      className={`border-b px-5 py-3 cursor-pointer ${
                        selectedReport === item.id ? "bg-blue-100 font-[600]" : ""
                      }`}
                      onClick={() => setSelectedReport(item.id)}
                    >
                      <p className="m-0">{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-3/4 border border-gray-300 rounded-md bg-white">
                <h4 className="p-3">Inventory Summary (Blinded)</h4>
                <Box sx={{ height: 400, width: "100%", display: "flex" }}>
                  <ThemeProvider theme={getMuiTheme}>
                    <StripedDataGrid
                      className="allQuestionnaire-grid"
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20]}
                      components={{ Toolbar: GridToolbar }}
                      pagination
                      rowHeight={38}
                      disableSelectionOnClick
                      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                  </ThemeProvider>
                </Box>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default ApproveQuarantine;
