import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
} from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { Row, Col } from "react-bootstrap";

import "../StudySetup.css";
import "./DrugReturn.css";
import {
  addNewNonRequestStatusServive,
  addNewRequestStatusServive,
  deleteDrugReturnAttributeService,
  deleteNewNonRequestStatusServive,
  getAllDataTypesService,
  getAllDrugReturnDataService,
  saveAttributesService,
  updateEnableAttributesService,
  updatePillCountService,
} from "./DrugReturnConfig";
import DeleteModal from "../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const ApproveQuarantine = () => {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const location = useLocation();

  const id = location.state.id;

  const [dataTypes, setDataTypes] = useState([]);

  const [attributesData, setAttributeData] = React.useState([]);
  const [drugReturnChoices, setDrugReturnChoices] = useState([]);
  const [drugCriteriaChoices, setDrugCriteriaChoices] = useState([]);

  const [drugReturnData, setDrugReturnData] = useState([]);

  const [selectedDrugChoice, setSelectedDrugChoice] = useState([]);

  const [drugCriteriaData, setDrugCriteriaData] = useState([]);
  const [selectedDrugCriteria, setSelectedDrugCriteria] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [horizontalId, setHorizontalId] = useState(null);
  const [verticalId, setVerticalId] = useState(null);
  const [showStatusConfirmModal, setShowStatusConfirmModal] = useState(false);
  const [deleteStatusId, setDeleteStatusId] = useState(null);
  const [deleteType, setDeleteType] = useState("");
  const [pageName, setPageName] = useState("");

  const rows = attributesData.map((row) => ({
    id: row.id,
    displayName: row.displayName,
    errorMessage: row.errorMessage,
    minimumValue: row.minimumValue,
    maximumValue: row.maximumValue,
    maximumLength: row.maximumLength,
    dataTypeId: dataTypes?.find((item) => item?.id === row?.dataTypeId).name,
    // dataType: row.dataType,
    removeId: row.id,
  }));

  // useEffect(() => {
  //   window.scrollTo(0, 40);
  // }, []);

  useEffect(() => {
    const filterData = drugReturnChoices?.filter(
      (item) =>
        item?.name === "Available At Site" ||
        item?.name === "Available At Depot"
    );
    const filterChoices = drugReturnChoices?.filter(
      (item) =>
        item?.name !== "Available At Site" &&
        item?.name !== "Available At Depot"
    );
    setDrugReturnData(filterData);
    setDrugReturnChoices(filterChoices);
  }, []);

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      renderHeader: () => <div>{"ID"}</div>,
      width: "200",
    },
    {
      field: "displayName",
      // hide: true,
      headerName: "Name",
      renderHeader: () => <div className="grid-heading">{"Name"}</div>,
      width: "200",
      renderCell: (params) => {
        const name = params.row.displayName;

        return (
          <div className="grid-body">
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "errorMessage",
      // hide: true,
      headerName: "Error Message",
      renderHeader: () => <div className="grid-heading">{"Error Message"}</div>,
      width: "200",
      renderCell: (params) => {
        const errorMsg = params.row.errorMessage;

        return (
          <div className="grid-body">
            <p>{errorMsg}</p>
          </div>
        );
      },
    },
    {
      field: "minimumValue",
      // hide: true,
      headerName: "Minimum",
      renderHeader: () => <div className="grid-heading">{"Minimum"}</div>,
      width: "200",
      renderCell: (params) => {
        const minValue = params.row.minimumValue;

        return (
          <div className="grid-body">
            <p>{minValue}</p>
          </div>
        );
      },
    },
    {
      field: "maximumValue",
      // hide: true,
      headerName: "maximum",
      renderHeader: () => <div className="grid-heading">{"Maximum"}</div>,
      width: "200",
      renderCell: (params) => {
        const maxValue = params.row.maximumValue;

        return (
          <div className="grid-body">
            <p>{maxValue}</p>
          </div>
        );
      },
    },
    {
      field: "maximumLength",
      // hide: true,
      headerName: "Length",
      renderHeader: () => <div className="grid-heading">{"Length"}</div>,
      width: "200",
      renderCell: (params) => {
        const minLength = params.row.maximumLength;

        return (
          <div className="grid-body">
            <p>{minLength}</p>
          </div>
        );
      },
    },
    {
      field: "dataTypeId",
      // hide: true,
      headerName: "Data Type",
      renderHeader: () => <div className="grid-heading">{"Data Type"}</div>,
      width: "200",
      renderCell: (params) => {
        const dataType = params?.row?.dataTypeId;

        return (
          <div className="grid-body">
            <p>{dataType}</p>
          </div>
        );
      },
    },
    {
      field: "removeId",
      // hide: true,
      headerName: "Remove",
      renderHeader: () => <div className="grid-heading">{"Remove"}</div>,
      width: "200",
      renderCell: (params) => {
        const id = params.row.removeId;
        return (
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => {
                // removeAttributeData(id);
                setDeleteId(id);
                setShowConfirmModal(true);
              }}
              style={{
                cursor: "pointer",
                textAlign: "center",
                background: "red",
                border: "none",
                borderRadius: "5px",
                padding: "10px 10px",
              }}
            >
              <FaTrashAlt
                color="white"
                style={{
                  fontSize: "15px",
                }}
              />
            </button>
          </div>
        );
      },
    },
  ];

  const notify = () =>
    toast.success("Attribute Added Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notifyStatus = () =>
    toast.success("Choice Added Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notifyUpdateStatus = () =>
    toast.success("Updated Choice Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notifyDelete = () =>
    toast.success("Attribute Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notifyDeleteStatus = () =>
    toast.success("Choice Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  // const createVisitSchema = yup.object().shape({
  //   name: yup.string().required("This field is required"),
  //   daysExpected: yup.string().required("This field is required"),
  //   windowBefore: yup.string().required("This field is required"),
  //   windowAfter: yup.string().required("This field is required"),
  //   note: yup.string().required("This field is required"),
  //   lastUpdate: yup.string().required("This field is required"),
  //   windowOverride: yup
  //     .bool()
  //     .oneOf([true, false], "Window override is required"),
  //   isScheduled: yup.bool().oneOf([true, false], "Is scheduled is required"),
  //   interviewMode: yup
  //     .bool()
  //     .oneOf([true, false], "Interview mode is required"),
  //   closeOut: yup.bool().oneOf([true, false], "Closeout mode is required"),
  //   alwaysAvailable: yup
  //     .bool()
  //     .oneOf([true, false], "Always available is required"),
  //   reasonFlag: yup.string().required("This field is required"),
  //   visitOrder: yup.string().required("This field is required"),
  //   visitAnchor: yup.string().required("This field is required"),
  //   visitsTop: yup.string().required("This field is required"),
  //   dosageModule: yup
  //     .bool()
  //     .oneOf([true, false], "Dosage module mode is required"),
  // });

  const createAttributeScheme = yup.object().shape({
    attributeName: yup.string().required("This field is required"),
    attributeMinimumValue: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    attributeErrorMessage: yup.string().required("This field is required"),
    attributeMaximumValue: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    dataTypeId: yup.string().required("This field is required"),
    attributeMaximumLength: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
  });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(createVisitSchema),
  // });

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createAttributeScheme),
  });

  useEffect(() => {
    getAllDataTypes();
  }, []);

  useEffect(() => {
    if (id) {
      getAllDrugReturnData(id);
    }
  }, [id]);

  const onSubmit = async (data) => {
    setLoad(true);
    try {
      data.drugReturnControlId = id;
      const res = await saveAttributesService(data);
      if (res?.status) {
        setLoad(false);
        getAllDrugReturnData(id);
        notify();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      requestFailed(err.message);
      setLoad(false);
    }
    reset({
      dataTypeId: "", // Reset select field to empty value or default value
    });
  };

  const textBoxStyles = {
    fontSize: 15,
    // width: "400px",
    height: "10px",
    padding: "11px 14px"
  };

  const selectStyles = {
    width: "100%",
    overFlowY: "auto",
    marginTop: 1,
  };

  const selectStylesCol = {
    width: "100%",
    // marginTop: 3,
  };

  const getAllDataTypes = async () => {
    try {
      const res = await getAllDataTypesService();
      if (res?.status) {
        setDataTypes(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const getAllDrugReturnData = async (id) => {
    setLoad(true);
    try {
      const res = await getAllDrugReturnDataService(id);
      if (res?.status) {
        setLoad(false);
        setAttributeData(
          res?.data?.result?.result[0]?.drugReturnControlAttributes
        );
        setPageName(res?.data?.result?.result[0]?.name);
        setDrugReturnChoices(res?.data?.result?.nonRequestStatusTypes);
        setDrugCriteriaChoices(res?.data?.result?.requestStatusTypes);
        res?.data?.result?.result?.forEach((item) => {
          if (item?.isRequest) {
            setVerticalId(item?.id);
            setDrugCriteriaData(item?.drugStatuses);
          } else {
            setHorizontalId(item?.id);
            setDrugReturnData(item?.drugStatuses);
          }
        });
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };
  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteDrugReturnAttributeService(id, deleteId);

      if (res.status) {
        setLoad(false);
        getAllDrugReturnData(id);
        setShowConfirmModal(false);
        notifyDelete();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  const handleDeleteStatusConfirm = async () => {
    try {
      setLoad(true);
      let data = {
        drugReturnTypeId:
          deleteType === "nonStatus" ? horizontalId : verticalId,
        drugStatusTypeId: deleteStatusId,
        drugReturnGroupId: id,
        studyRoleId: "",
      };
      const res = await deleteNewNonRequestStatusServive(data);

      if (res.status) {
        setLoad(false);
        notifyDeleteStatus();
        getAllDrugReturnData(id);
        setShowStatusConfirmModal(false);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowStatusConfirmModal(false);
      requestFailed(err.message);
    }
  };

  const addDrugChoiceColumns = async (e) => {
    e.preventDefault();
    if (selectedDrugChoice?.length > 0) {
      try {
        setLoad(true);
        let data = [];

        selectedDrugChoice.forEach((item) => {
          let obj = {
            drugReturnTypeId: horizontalId,
            nonRequestDrugStatusId: item,
            drugReturnGroupId: id,
            studyRoleId: "",
          };
          data.push(obj);
        });

        const res = await addNewNonRequestStatusServive(data);

        if (res.status) {
          setLoad(false);
          notifyStatus();
          getAllDrugReturnData(id);
          setSelectedDrugChoice([]);
        }
      } catch (err) {
        console.log("Error: ", err.message);
        setLoad(false);
        requestFailed(err.message);
      }
    }
  };

  const addDrugCriteriaColumn = async (e) => {
    e.preventDefault();
    if (selectedDrugCriteria?.length > 0) {
      try {
        setLoad(true);
        let data = [];
        selectedDrugCriteria.forEach((item) => {
          let obj = {
            drugReturnTypeId: verticalId,
            requestDrugStatusId: item,
            drugReturnGroupId: id,
            studyRoleId: "",
          };
          data.push(obj);
        });
        const res = await addNewRequestStatusServive(data);

        if (res.status) {
          setLoad(false);
          notifyStatus();
          getAllDrugReturnData(id);
          setSelectedDrugCriteria([]);
        }
      } catch (err) {
        console.log("Error: ", err.message);
        setLoad(false);
        requestFailed(err.message);
      }
    }
  };

  const updatePillCount = async (item) => {
    try {
      setLoad(true);
      let data = {
        drugReturnTypeId: horizontalId,
        drugStatusTypeId: item?.id,
        enablePillCount: !item?.enablePillCount,
      };
      const res = await updatePillCountService(data);

      if (res.status) {
        setLoad(false);
        notifyUpdateStatus();
        getAllDrugReturnData(id);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    }
  };

  const updateEnableAttributes = async (item) => {
    try {
      setLoad(true);
      let data = {
        drugReturnTypeId: horizontalId,
        drugStatusTypeId: item?.id,
        enableAttributes: !item?.enableAttributes,
      };
      const res = await updateEnableAttributesService(data);

      if (res.status) {
        setLoad(false);
        notifyUpdateStatus();
        getAllDrugReturnData(id);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/drug-return">Drug Return</Link> |{" "}
            <button
              style={{
                background: "none",
                color: "#337AB7",
                border: "none",
                padding: "0",
                font: "inherit",
                cursor: "pointer",
              }}
              onClick={async () => {
                navigate(`/study-management/drug-return-page`, {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              {pageName}
            </button>
            -{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box
            component="form"
            // onSubmit={handleSubmit(onSubmit)}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <div className="createVisitBody">
              <div className="createVisitHeader">
                <h1>{pageName} Control Setup</h1>
              </div>
              <div className="createVisitHeader">
                <p>
                  The statuses across the top are the choices to be displayed to
                  the user. The Statuses on the left side are the CRITERIA TO
                  PULL DRUG TO BE EDITED
                </p>
              </div>
              <div
                className=""
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid #e0d8d8",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <h4 style={{ paddingLeft: "5px" }}>
                      Update to Drug Status
                    </h4>
                  </div>
                  <div className="drugBody">
                    <div className="drugColumn">
                      {drugReturnData &&
                        drugReturnData?.map((item) => (
                          <div
                            className="approveQuarantineItem"
                            key={item?.id}
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className="approveQuarantineItemBody"
                              style={{ height: "fit-content" }}
                            >
                              <div className="approveQuarantineItemBodyHeading">
                                <h4>{item?.description}</h4>
                              </div>
                              <div className="approveQuarantineCheckbox">
                                <p>Enable pills count</p>
                                <Checkbox
                                  className="approveQuarantineCheckboxStyles"
                                  checked={item?.enablePillCount}
                                  onChange={() => {
                                    updatePillCount(item);
                                  }}
                                />
                              </div>
                              <div className="approveQuarantineCheckbox">
                                <p>Enable Attributes</p>
                                <Checkbox
                                  checked={item?.enableAttributes}
                                  className="approveQuarantineCheckboxStyles"
                                  onChange={() => updateEnableAttributes(item)}
                                />
                              </div>
                              <div className="approveQuarantineButton">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteType("nonStatus");
                                    setDeleteStatusId(item?.id);
                                    setShowStatusConfirmModal(true);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="approveQuarantineItem">
                      <div className="approveQuarantineItemBody">
                        <FormControl sx={selectStyles}>
                          <Select
                            placeholder="Select Option"
                            inputProps={{
                              style: textBoxStyles,
                              "aria-label": "Without label",
                              className: "custom-input-class",
                            }}
                            value={selectedDrugChoice}
                            multiple
                            renderValue={(selected) => {
                              const selectedItems = drugReturnChoices.filter(
                                (item) => selected.includes(item?.id)
                              );
                              if (selectedItems.length === 1) {
                                return <b>{selectedItems[0]?.description}</b>;
                              } else if (selectedItems.length > 1) {
                                return (
                                  <b>{`${selectedItems[0]?.description} +${
                                    selectedItems.length - 1
                                  }`}</b>
                                );
                              }
                              return "";
                            }}
                            onChange={(e) => {
                              const { value } = e.target;
                              setSelectedDrugChoice(
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Option</em>
                            </MenuItem>
                            {drugReturnChoices?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                <Checkbox
                                  className="approveQuarantineCheckboxStyles"
                                  checked={selectedDrugChoice.includes(
                                    item?.id
                                  )}
                                />
                                {/* <ListItemText se primary={item?.description} /> */}
                                <h5>{item?.description}</h5>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <div className="approveQuarantineAddButton">
                          <button
                            onClick={(e) => {
                              addDrugChoiceColumns(e);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="approveQuarantineCol"
                  style={{
                    border: "2px solid #e0d8d8",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <h4 style={{ paddingLeft: "5px" }}>Current Drug Status</h4>
                  </div>
                  <div className="drugChoiceColumn">
                    {drugCriteriaData &&
                      drugCriteriaData?.map((item) => (
                        <div key={item?.id} style={{ padding: "0px" }}>
                          <div
                            className="approveQuarantineItemBody"
                            style={{ padding: "0px 15px" }}
                          >
                            <div className="approveQuarantineItemBodyHeading">
                              <h4>{item?.description}</h4>
                            </div>
                            <div className="approveQuarantineButton">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeleteType("status");
                                  setShowStatusConfirmModal(true);
                                  setDeleteStatusId(item?.id);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                  </div>
                  <div className="approveQuarantineItem">
                    <div className="approveQuarantineItemBody">
                      <FormControl sx={selectStylesCol}>
                        <Select
                          placeholder="Select Option"
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          value={selectedDrugCriteria}
                          multiple
                          renderValue={(selected) => {
                            const selectedItems = drugCriteriaChoices.filter(
                              (item) => selected.includes(item?.id)
                            );
                            if (selectedItems.length === 1) {
                              return <b>{selectedItems[0]?.description}</b>;
                            } else if (selectedItems.length > 1) {
                              return (
                                <b>{`${selectedItems[0]?.description} +${
                                  selectedItems.length - 1
                                }`}</b>
                              );
                            }
                            return "";
                          }}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSelectedDrugCriteria(
                              typeof value === "string"
                                ? value.split(",")
                                : value
                            );
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Option</em>
                          </MenuItem>
                          {drugCriteriaChoices?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              <Checkbox
                                className="approveQuarantineCheckboxStyles"
                                checked={selectedDrugCriteria.includes(
                                  item?.id
                                )}
                              />
                              {/* <ListItemText primary={item?.description} /> */}
                              <h5>{item?.description}</h5>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <div className="approveQuarantineAddButton">
                        <button onClick={(e) => addDrugCriteriaColumn(e)}>
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>

          {attributesData && attributesData?.length > 0 && (
            <>
              <div className="approveQuarantineAttributes">
                <h4>Attributes to collect</h4>
              </div>

              <Box sx={{ height: 400, width: "100%", display: "flex" }}>
                <ThemeProvider theme={getMuiTheme}>
                  <StripedDataGrid
                    className="allQuestionnaire-grid"
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    components={{ Toolbar: GridToolbar }}
                    pagination
                    rowHeight={38}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                  />
                </ThemeProvider>
              </Box>
            </>
          )}

          <div className="approveQuarantineAttributes">
            <h4>Add Attributes</h4>
          </div>

          <Box sx={{ marginTop: "2%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row style={{ marginTop: "5px" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Name{" "}
                    {errors.name?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <TextField
                    className="nameField"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    type="text"
                    name="attributeName"
                    {...register("attributeName", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.attributeName && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.attributeName.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <span className="error-text">{errors.name?.message}</span> */}
                </Col>
                <Col md={2}></Col>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Minimum Value{" "}
                    {errors.attributeMinimumValue?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>

                  <TextField
                    className="nameField"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    type="number"
                    name="attributeMinimumValue"
                    {...register("attributeMinimumValue", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.attributeMinimumValue && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.attributeMinimumValue.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "2%" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Error Message{" "}
                    {errors.attributeErrorMessage?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <TextField
                    className="nameField"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    type="text"
                    name="attributeErrorMessage"
                    {...register("attributeErrorMessage", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.attributeErrorMessage && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.attributeErrorMessage.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
                <Col md={2}></Col>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Maximum Value{" "}
                    {errors.attributeMaximumValue?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>

                  <TextField
                    className="nameField"
                    type="number"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    name="attributeMaximumValue"
                    {...register("attributeMaximumValue", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.attributeMaximumValue && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.attributeMaximumValue.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "2%" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Data Type{" "}
                    {errors.dataTypeId?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>

                  <FormControl className="nameField">
                    <Select
                      name="dataTypeId"
                      {...register("dataTypeId")}
                      inputProps={{ "aria-label": "Without label", className: "custom-input-class", }}
                      onChange={(e) => {
                        setValue("dataTypeId", e.target.value, {
                          shouldValidate: true,
                        });
                        setShowDialog(true);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {dataTypes?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.dataTypeId && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.dataTypeId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
                <Col md={2}></Col>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Minimum Length{" "}
                    {errors.attributeMaximumLength?.message ? (
                      <span className="error-highlight">*</span>
                    ) : (
                      <></>
                    )}
                  </label>

                  <TextField
                    className="nameField"
                    type="number"
                    name="attributeMaximumLength"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("attributeMaximumLength", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.attributeMaximumLength && (
                    <>
                      <div
                        className="createCaregiverFlexEnd"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.attributeMaximumLength.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "3%" }}>
                <Col md={6}>
                  <button className="backToDrugButton" type="submit">
                    Add
                  </button>
                </Col>
              </Row>
              <Row style={{ marginTop: "3%" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="createSponsor-buttons">
                    {/* <button
                      className="sponsorCancelButton"
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                    >
                      Cancel
                    </button> */}
                    <Link to="/study-management/drug-return">
                      <button className="backToDrugButton" type="submit">
                        Back
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </form>
          </Box>
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Attribute"}
        />
      )}
      {showStatusConfirmModal && (
        <DeleteModal
          open={showStatusConfirmModal}
          handleClose={() => setShowStatusConfirmModal(false)}
          handleDelete={handleDeleteStatusConfirm}
          subject={"Choice"}
        />
      )}
    </>
  );
};

export default ApproveQuarantine;
