import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import parse from "html-react-parser";
import {
  editQuestionnaireService,
  getAllQuestionnaireDropdownsDataService,
  getBusinessRulesDropdownService,
  getLanguagesServices,
  getQuestionnaireById,
  getQuestionnaireByIdSplitOneService,
  getQuestionnaireByIdSplitTwoService,
} from "./Questionnaire";
import DialogBox from "../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import { linkButton } from "../Visits/ConfigureNavigations";
import SubmitActionDragDrop from "../../../../components/DndComponent";
import QuestionnaireCalculation from "./QuestionnaireCalculation";
import RichTextEditor from "../../../../components/RichTextEditor";

export const selectStyles = {
  width: 530,
  marginTop: 1,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const rowStyles = {
  marginTop: "2%",
  marginBottom: "2%",
};

const labelStyles = {
  display: "flex",
  alignItems: "center",
  height: "30px",
};

const textBoxBody = {
  marginLeft: "2px",
};

const textBoxStyles = {
  fontSize: 15,
  width: "500px",
  height: "10px",
  padding: "11px 14px"
};

const EditQuestionnaire = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;

  const [load, setLoad] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [value, setTabValue] = useState(0);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [editorContent, setEditorContent] = useState("");
  const [translationKey, setTranslationKey] = useState("n/a");
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [questionnaireTypeData, setQuestionnaireTypeData] = useState([]);
  const [visibleBusinessData, setVisibleBusinessData] = useState([]);
  const [enableBusinessData, setEnableBusinessData] = useState([]);
  const [openEndedData, setOpenEndedData] = useState([]);
  const [visitQuestionnaireData, setVisitQuestionnaireData] = useState([]);
  const [allSubmitActions, setAllSubmitActions] = useState([]);
  const [assignedSubmitActions, setAssignedSubmitActions] = useState([
    { id: "-1", displayName: "" },
  ]);
  const [gotFormData, setGotFormData] = useState(false);
  const [calculationDropdownData, setCalculationDropdownData] = useState([]);
  const [calcualationListingData, setCalculationListingData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [translationLanguages, setTranslationLanguages] = useState(
    Array().fill("")
  );

  const [formData, setFormData] = useState({
    internalNameState: "",
    questionnaireTypeIdState: "",
    sequenceState: "",
    isBlindedState: false,
    displaySummaryScoreState: false,
    isDropdownNavigationEnabledState: false,
    validateQuestionnaireOnSaveState: false,
    promptIncompletePagesOnSaveState: false,
    visibleBusinessRuleIdState: "",
    enableBusinessRuleIdState: "",
    openEndedBusinessRuleIdState: "",
    enableVisitQuestionnaireIdState: "",
    previousDaysEntryState: "",
    enforcePreviousDaysEntryState: false,
    allowEditState: false,
  });

  const {
    allowEditState,
    displaySummaryScoreState,
    enableBusinessRuleIdState,
    enableVisitQuestionnaireIdState,
    enforcePreviousDaysEntryState,
    internalNameState,
    isBlindedState,
    isDropdownNavigationEnabledState,
    openEndedBusinessRuleIdState,
    previousDaysEntryState,
    promptIncompletePagesOnSaveState,
    questionnaireTypeIdState,
    sequenceState,
    validateQuestionnaireOnSaveState,
    visibleBusinessRuleIdState,
  } = formData;

  const notify = () =>
    toast.success("Questionnaire Updated Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const createQuestionnaireSchema = yup.object().shape({
    // translationKey: yup.string().required("This field is required"),
    internalName: yup.string().required("This field is required"),
    questionnaireTypeId: yup.string().required("This field is required"),
    sequence: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
    isBlinded: yup.bool().oneOf([true, false]),
    displaySummaryScore: yup.bool().oneOf([true, false]),
    isDropdownNavigationEnabled: yup.bool().oneOf([true, false]),
    validateQuestionnaireOnSave: yup.bool().oneOf([true, false]),
    promptIncompletePagesOnSave: yup.bool().oneOf([true, false]),
    visibleBusinessRuleId: yup.string().required("This field is required"),
    enableBusinessRuleId: yup.string().required("This field is required"),
    openEndedBusinessRuleId: yup.string().required("This field is required"),
    enableVisitQuestionnaireId: yup.string().required("This field is required"),
    previousDaysEntry: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
    enforcePreviousDaysEntry: yup.bool().oneOf([true, false]),
    allowEdit: yup.bool().oneOf([true, false]),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createQuestionnaireSchema),
  });

  useEffect(() => {
    getAllDropdownData();
    getBusinessRuleData();
    getLanguagesData();
  }, []);

  useEffect(() => {
    if (id) {
      fetchQuestionnaireByIdSplitOne(id);
      // fetchQuestionnaireByIdSplitTwo(id);
    }
  }, [id]);

  // useEffect(() => {
  //   if (questionnaireSubmitActions?.length > 0 && id) {
  //     fetchQuestionnaireByIdSplitTwo(id);
  //   } else {
  //     setAllSubmitActions(unchangedSubmitActions);
  //   }
  // }, [questionnaireSubmitActions]);

  console.log("Languages", languages);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const stripPTags = (html) => {
    // Use regex to replace <p> and </p> tags with empty string
    const cleanHtml = html.replace(/<\/?p[^>]*>/gi, ""); // Remove <p> and </p> tags
    return cleanHtml;
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const getAllDropdownData = async () => {
    setLoad(true);
    try {
      const res = await getAllQuestionnaireDropdownsDataService();
      if (res?.status) {
        setLoad(false);
        setAllSubmitActions(res?.data?.result?.allSubmitActions);
        setQuestionnaireTypeData(res?.data?.result?.allQuestionnaireTypes);
        // setVisibleBusinessData(res?.data?.result?.allBusinessRules);
        // setOpenEndedData(res?.data?.result?.allBusinessRules);
        // setEnableBusinessData(res?.data?.result?.allBusinessRules);
        setVisitQuestionnaireData(res?.data?.result?.allVisitQuestionnaires);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const getLanguagesData = async () => {
    setLoad(true);
    try {
      const res = await getLanguagesServices();
      if (res?.status) {
        setLoad(false);
        setLanguages(
          res?.data?.result.filter((item) => item?.isActive === true)
        );
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const getBusinessRuleData = async () => {
    setLoad(true);
    try {
      const res = await getBusinessRulesDropdownService();
      if (res?.status) {
        setLoad(false);
        setVisibleBusinessData(res?.data?.result);
        setOpenEndedData(res?.data?.result);
        setEnableBusinessData(res?.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  // const fetchQuestionnaireById = async (id) => {
  //   setLoad(true);
  //   try {
  //     const res = await getQuestionnaireById(id);
  //     if (res?.status) {
  //       setLoad(false);
  //       console.log("resss", res);
  //       const data = res?.data?.result?.questionnaireData;
  //       const submitActions = res?.data?.result?.allSubmitActions;
  //       setTranslationKey(data?.translationKey);
  //       setFormData({
  //         internalNameState: data?.internalName,
  //         questionnaireTypeIdState: data?.questionnaireTypeId,
  //         sequenceState: data?.sequence,
  //         isBlindedState: data?.isBlinded,
  //         displaySummaryScoreState: data?.displaySummaryScore,
  //         isDropdownNavigationEnabledState: data?.isDropdownNavigationEnabled,
  //         validateQuestionnaireOnSaveState: data?.validateQuestionnaireOnSave,
  //         promptIncompletePagesOnSaveState: data?.promptIncompletePagesOnSave,
  //         visibleBusinessRuleIdState: data?.visibleBusinessRuleId,
  //         enableBusinessRuleIdState: data?.enableBusinessRuleId,
  //         openEndedBusinessRuleIdState: data?.openEndedBusinessRuleId,
  //         enableVisitQuestionnaireIdState: data?.enableVisitQuestionnaireId,
  //         previousDaysEntryState: data?.previousDaysEntry,
  //         enforcePreviousDaysEntryState: data?.enforcePreviousDaysEntry,
  //         allowEditState: data?.allowEdit,
  //       });
  //       let newAssignArray = data?.questionnaireSubmitActions?.map(
  //         (item) => item?.submitAction
  //       );
  //       setAssignedSubmitActions((prevAssignedSubmitActions) => [
  //         ...prevAssignedSubmitActions,
  //         ...data?.questionnaireSubmitActions?.map(
  //           (item) => item?.submitAction
  //         ),
  //       ]);
  //       setCalculationDropdownData(res?.data?.result?.calculations);
  //       const filteredArray = submitActions?.filter(
  //         (item1) => !newAssignArray.some((item2) => item1.id == item2.id)
  //       );
  //       setAllSubmitActions(filteredArray);
  //       setCalculationListingData(
  //         res?.data?.result?.visitQuestionnaireCalculations
  //       );
  //       setGotFormData(true);
  //       reset();
  //     }
  //   } catch (err) {
  //     setLoad(false);
  //     console.log("Error: ", err?.message);
  //   }
  // };

  const fetchQuestionnaireByIdSplitOne = async (id) => {
    setLoad(true);
    try {
      const res = await getQuestionnaireByIdSplitOneService(id);
      if (res?.status) {
        setLoad(false);
        const data = res?.data?.result?.questionnaireData;
        setTranslationKey(data?.translationKey);
        setFormData({
          internalNameState: data?.internalName,
          questionnaireTypeIdState: data?.questionnaireTypeId,
          sequenceState: data?.sequence,
          isBlindedState: data?.isBlinded,
          displaySummaryScoreState: data?.displaySummaryScore,
          isDropdownNavigationEnabledState: data?.isDropdownNavigationEnabled,
          validateQuestionnaireOnSaveState: data?.validateQuestionnaireOnSave,
          promptIncompletePagesOnSaveState: data?.promptIncompletePagesOnSave,
          visibleBusinessRuleIdState: data?.visibleBusinessRuleId,
          enableBusinessRuleIdState: data?.enableBusinessRuleId,
          openEndedBusinessRuleIdState: data?.openEndedBusinessRuleId,
          enableVisitQuestionnaireIdState: data?.enableVisitQuestionnaireId,
          previousDaysEntryState: data?.previousDaysEntry,
          enforcePreviousDaysEntryState: data?.enforcePreviousDaysEntry,
          allowEditState: data?.allowEdit,
        });
        // setGotFormData(true);
        reset();
        await fetchQuestionnaireByIdSplitTwo(
          data?.questionnaireSubmitActions,
          id
        );
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const fetchQuestionnaireByIdSplitTwo = async (
    questionnaireSubmitActions,
    id
  ) => {
    setLoad(true);
    try {
      const res = await getQuestionnaireByIdSplitTwoService(id);
      if (res?.status) {
        setLoad(false);
        const submitActions = res?.data?.result?.allSubmitActions;
        setCalculationListingData(
          res?.data?.result?.visitQuestionnaireCalculations
        );
        setCalculationDropdownData(res?.data?.result?.calculations);

        let filteredArray = [];
        if (questionnaireSubmitActions?.length > 0) {
          filteredArray = submitActions?.filter(
            (item1) =>
              !questionnaireSubmitActions.some(
                (item2) => item1.id == item2.submitActionId
              )
          );
        }
        if (filteredArray?.length === 0) {
          setAllSubmitActions(submitActions);
        } else {
          setAllSubmitActions(filteredArray);
        }
        setAssignedSubmitActions((prevAssignedSubmitActions) => [
          ...prevAssignedSubmitActions,
          ...questionnaireSubmitActions
            ?.filter((questionnaireAction) =>
              submitActions.some(
                (action) => action.id === questionnaireAction.submitActionId
              )
            )
            ?.map((questionnaireAction) => {
              return submitActions.find(
                (action) => action.id === questionnaireAction.submitActionId
              );
            }),
        ]);

        setGotFormData(true);
        reset();
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const getSubmitActionString = (array) => {
    return array
      .slice(1) // Skip the first item
      .map((item) => item.id) // Extract id
      .join(", "); // Join with commas
  };

  const onSubmit = async (data) => {
    let newData = {
      internalName: data?.internalName,
      questionnaireTypeId: data?.questionnaireTypeId,
      translationKey: translationKey,
      url: null,
      visibleBusinessRuleId: data?.visibleBusinessRuleId,
      enableBusinessRuleId: data?.enableBusinessRuleId,
      openEndedBusinessRuleId: data?.openEndedBusinessRuleId,
      isBlinded: data?.isBlinded,
      displaySummaryScore: data?.displaySummaryScore,
      isDropdownNavigationEnabled: data?.isDropdownNavigationEnabled,
      validateQuestionnaireOnSave: data?.validateQuestionnaireOnSave,
      promptIncompletePagesOnSave: data?.promptIncompletePagesOnSave,
      enableVisitQuestionnaireId: data?.enableVisitQuestionnaireId,
      previousDaysEntry: data?.previousDaysEntry,
      previousDaysEdit: 0,
      enforcePreviousDaysEntry: data?.enforcePreviousDaysEntry,
      allowEdit: data?.allowEdit,
      sequence: data?.sequence,
      syncVersion: 0,
      isDirty: true,
      submitActions: getSubmitActionString(assignedSubmitActions),
    };

    try {
      setLoad(true);
      setShowDialog(false);
      const res = await editQuestionnaireService(newData, id);

      if (res.status) {
        setLoad(false);
        notify();
        navigate("/study-management/questionnaires");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (isDirty) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/questionnaires");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/questionnaires");
    }
  };

  const handleTranslationLanguage = (value, index) => {
    setTranslationLanguages((prevTranslationLanguages) => {
      const updatedLanguages = [...prevTranslationLanguages];
      updatedLanguages[index] = value; // Sets value at specific index
      return updatedLanguages;
    });
  };

  console.log("language", translationLanguages);

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <Link to="/study-management">Manage Your Study</Link> |{" "}
              <Link to="/study-management/questionnaires">Questionnaire</Link> |{" "}
              <Link to="/study-management/questionnaires/edit-questionnaire">
                Edit Questionnaire
              </Link>{" "}
              -
              <b style={{ color: "gray" }}>
                {sessionStorage?.getItem("studyName")}
              </b>
            </p>
            <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Col md={6}>
                <div className="study-management-head-start">
                  <p className="study-management-heading">Edit Questionnaire</p>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{
                  width: "fit-content",
                }}
              >
                <Tab label="Questionnaire" {...a11yProps(0)} />
                <Tab label="Calculations" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Box
                  component="form"
                  sx={{
                    height: "auto",
                    width: "100%",
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <Row>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Translation Key
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <div>
                        <b>Current Translated Value</b>
                      </div>
                      {/* <div dangerouslySetInnerHTML={{ __html: editorContent }} /> */}
                      {/* <TextField
                    dangerouslySetInnerHTML={{ __html: editorContent }}
                    style={textBoxBody}
                    multiline
                    rows={2}
                    value={parse(stripPTags(editorContent)) || "n/a"}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("translationKey", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  /> */}
                      <div
                        style={{
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          padding: "10px",
                          minHeight: "80px",
                          marginBottom: "10px",
                          width: "530px",
                          backgroundColor: "#fff", // Styling to make it look more like a "field"
                        }}
                      >
                        {translationKey}
                      </div>
                      {/* {errors.translationKey && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.translationKey.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )} */}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}></Col>
                    <Col md={10}>
                      <button
                        style={linkButton}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowEditorModal(true);
                        }}
                      >
                        Edit Translation
                      </button>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Internal Name
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <TextField
                        value={internalNameState}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("internalName", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              internalNameState: e.target?.value,
                            }));
                          },
                        })}
                      />
                      {errors.internalName && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.internalName.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Questionnaire Type Id
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <FormControl sx={selectStyles}>
                        <Select
                          name="questionnaireTypeId"
                          value={questionnaireTypeIdState}
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...register("questionnaireTypeId", {
                            onChange: (e) => {
                              setValue("questionnaireTypeId", e.target.value, {
                                shouldValidate: true,
                              });
                              setFormData((prevData) => ({
                                ...prevData,
                                questionnaireTypeIdState: e.target.value,
                              }));
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {questionnaireTypeData &&
                            questionnaireTypeData?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {errors.questionnaireTypeId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.questionnaireTypeId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Sequence <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <div>
                        <b>
                          This is used to order questionnaires on handheld that
                          are NOT associated with a Visit.
                        </b>
                      </div>
                      <TextField
                        type="number"
                        value={sequenceState}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("sequence", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              sequenceState: e.target.value,
                            }));
                          },
                        })}
                      />
                      {errors.sequence && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.sequence.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        IsBlinded <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={isBlindedState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isBlinded", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              isBlindedState: e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.isBlinded && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.isBlinded.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Display Summary Score{" "}
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={displaySummaryScoreState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("displaySummaryScore", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              displaySummaryScoreState: e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.displaySummaryScore && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.displaySummaryScore.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Is Dropdown Navigation Enabled{" "}
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={isDropdownNavigationEnabledState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isDropdownNavigationEnabled", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              isDropdownNavigationEnabledState:
                                e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.isDropdownNavigationEnabled && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.isDropdownNavigationEnabled.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Validate Questionnaire On Save{" "}
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={validateQuestionnaireOnSaveState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("validateQuestionnaireOnSave", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              validateQuestionnaireOnSaveState:
                                e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.validateQuestionnaireOnSave && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.validateQuestionnaireOnSave.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Prompt Incomplete Pages On Save{" "}
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={promptIncompletePagesOnSaveState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("promptIncompletePagesOnSave", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              promptIncompletePagesOnSaveState:
                                e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.promptIncompletePagesOnSave && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.promptIncompletePagesOnSave.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Visible Business Rule Id
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <FormControl sx={selectStyles}>
                        <Select
                          value={visibleBusinessRuleIdState}
                          name="visibleBusinessRuleId"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...register("visibleBusinessRuleId", {
                            onChange: (e) => {
                              setValue(
                                "visibleBusinessRuleId",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                              setFormData((prevData) => ({
                                ...prevData,
                                visibleBusinessRuleIdState: e.target.value,
                              }));
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {visibleBusinessData &&
                            visibleBusinessData?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {errors.visibleBusinessRuleId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.visibleBusinessRuleId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Enable Business Rule Id
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <FormControl sx={selectStyles}>
                        <Select
                          value={enableBusinessRuleIdState}
                          name="enableBusinessRuleId"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...register("enableBusinessRuleId", {
                            onChange: (e) => {
                              setValue("enableBusinessRuleId", e.target.value, {
                                shouldValidate: true,
                              });
                              setShowDialog(true);
                              setFormData((prevData) => ({
                                ...prevData,
                                enableBusinessRuleIdState: e.target.value,
                              }));
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {enableBusinessData &&
                            enableBusinessData?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {errors.enableBusinessRuleId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.enableBusinessRuleId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Open Ended Business Rule Id
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <FormControl sx={selectStyles}>
                        <Select
                          value={openEndedBusinessRuleIdState}
                          name="openEndedBusinessRuleId"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...register("openEndedBusinessRuleId", {
                            onChange: (e) => {
                              setValue(
                                "openEndedBusinessRuleId",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                              setFormData((prevData) => ({
                                ...prevData,
                                openEndedBusinessRuleIdState: e.target.value,
                              }));
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {openEndedData &&
                            openEndedData?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {errors.openEndedBusinessRuleId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.openEndedBusinessRuleId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Enable Visit Questionnaire Id
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <div>
                        <b>
                          This diary will not be available until the specified
                          Visit / Questionnaire is completed.
                        </b>
                      </div>
                      <FormControl sx={selectStyles}>
                        <Select
                          value={enableVisitQuestionnaireIdState}
                          name="enableVisitQuestionnaireId"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                            className: "custom-input-class",
                          }}
                          {...register("enableVisitQuestionnaireId", {
                            onChange: (e) => {
                              setValue(
                                "enableVisitQuestionnaireId",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                              setFormData((prevData) => ({
                                ...prevData,
                                enableVisitQuestionnaireIdState: e.target.value,
                              }));
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {visitQuestionnaireData &&
                            visitQuestionnaireData?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                {item?.visitQuestionnaireName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {errors.enableVisitQuestionnaireId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.enableVisitQuestionnaireId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Previous Days Entry
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <div>
                        <b>
                          This indicates how many days previous MUST be
                          completed in the scale. 0 indicates just today. This
                          would typically NOT have an available businessrule id.
                        </b>
                      </div>
                      <TextField
                        type="number"
                        value={previousDaysEntryState}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("previousDaysEntry", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              previousDaysEntryState: e.target.value,
                            }));
                          },
                        })}
                      />
                      {errors.previousDaysEntry && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.previousDaysEntry.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Enforce Previous Days Entry{" "}
                        <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={enforcePreviousDaysEntryState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("enforcePreviousDaysEntry", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              enforcePreviousDaysEntryState: e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.enforcePreviousDaysEntry && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.enforcePreviousDaysEntry.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Allow Edit <span className="error-highlight">*</span>
                      </p>
                    </Col>
                    <Col md={10}>
                      <Checkbox
                        checked={allowEditState}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("allowEdit", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              allowEditState: e.target.checked,
                            }));
                          },
                        })}
                      />
                      {errors.allowEdit && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.allowEdit.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={2}>
                      <p className="descriptionLabel" style={labelStyles}>
                        Submit Actions
                      </p>
                    </Col>
                    <Col md={10}>
                      {allSubmitActions && allSubmitActions?.length > 0 && (
                        <SubmitActionDragDrop
                          initialUnassigned={allSubmitActions}
                          assignedTasks={assignedSubmitActions}
                          setAssignedTasks={setAssignedSubmitActions}
                          gotData={gotFormData}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row style={rowStyles}>
                    <Col md={8}></Col>
                    <Col md={2}>
                      <div className="study-management-head-end">
                        <button
                          onClick={(e) => {
                            handleCancel(e);
                          }}
                          className="study-management-cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="study-management-head-end">
                        <button
                          type="submit"
                          className="study-management-create-btn-md"
                        >
                          Update
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <QuestionnaireCalculation
                  calculationDropdown={calculationDropdownData}
                  calcualationListingData={calcualationListingData}
                  questionnaireId={id}
                  getData={fetchQuestionnaireByIdSplitTwo}
                  setLoad={setLoad}
                />
              </TabPanel>
            </Box>
          </div>
          {showEditorModal && (
            <div
              style={{ width: "1200px", height: "500px", fontWeight: "600" }}
            >
              <Dialog open={showEditorModal} onClose={() => {}} sx={{ zIndex: 13000, top: "25px" }}>
                <DialogTitle style={{ fontSize: "20px", padding: "2px 13px" }}>
                  Translation Editor
                </DialogTitle>
                <Divider />
                <h3
                  style={{
                    fontSize: "16px",
                    padding: "2px 7px",
                    margin: "7px 9px",
                  }}
                >
                  Translation Languages:
                </h3>

                {languages &&
                  languages?.map((item, index) => (
                    <div style={{ height: "400px", margin: "6px 20px" }}>
                      <h4>{item?.languageDisplay}</h4>
                      <DialogContent>
                        <div style={{ height: "248px" }}>
                          <RichTextEditor
                            value={
                              index === 0
                                ? editorContent
                                : translationLanguages[index] || ""
                            }
                            onChange={(e) => {
                              index === 0
                                ? handleEditorChange(e) // `e` is the content directly from Quill
                                : handleTranslationLanguage(e, index);
                            }}
                          />
                        </div>
                      </DialogContent>
                    </div>
                  ))}
                <div className="my-10">
                  <h4 className="text-md py-1 px-8 mx-2.5 mb-6">
                    Replace with another translation set:
                  </h4>
                  <div className="px-16 flex gap-6">
                    <TextField
                      id="search-bar"
                      className="w-full"
                      variant="outlined"
                      placeholder="Search..."
                      size="small"
                    />
                    <Button size="large" variant="contained" onClick={() => {}}>
                      Search
                    </Button>
                  </div>
                </div>
                <DialogActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setTranslationKey(parse(stripPTags(editorContent)));
                      setShowEditorModal(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button size="large" variant="contained" onClick={() => {}}>
                    Save as Copy
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    color="error"
                    onClick={() => {
                      setEditorContent(translationKey);
                      setShowEditorModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditQuestionnaire;
