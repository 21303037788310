import { makeStyles } from "@mui/styles";
import * as yup from "yup";

export const useStyles = makeStyles((theme) => ({
  customCheckboxWrapper: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customCheckbox: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem !important",
    },
  },
  customSelect: {
    width: "100% !important",
  },
  input: {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      height: "5px",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

export const drugSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  code: yup.string().required("This field is required"),
  isBulk: yup.bool().oneOf([true, false], "Bulk is required"),
  // resupplyGroup: yup.number().required("This field is required"),
  // shippingGroup: yup.string().required("This field is required"),
  blindingGroupId: yup.string().required("This field is required"),
  maxReturnablePillCount: yup.number().required("This field is required"),
  packageId: yup.string().required("This field is required"),
  triggerTemperatureMin: yup.number().required("This field is required"),
  triggerTemperatureMax: yup.number().required("This field is required"),
  absoluteTemperatureMin: yup.number().required("This field is required"),
  absoluteTemperatureMax: yup.number().required("This field is required"),
  // allowDurationBelowMin: Yup.object({
  //   days: Yup.number().required('Required'),
  //   hours: Yup.number().required('Required'),
  //   minutes: Yup.number().required('Required')
  // }),
  // allowDurationAboveMax: Yup.object({
  //   days: Yup.number().required('Required'),
  //   hours: Yup.number().required('Required'),
  //   minutes: Yup.number().required('Required')
  // }),
});
