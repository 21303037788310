import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../StudySetup.css";
import "../StudyFiles/StudyFiles.css";
import "./Translation.css";
import { getAllExport } from "../../../../../services/Translation";

// CSV Generation Function
const generateCSV = (data) => {
  const headers = ["Language", "Key", "Text"];
  const rows = data.map((item) => [
    item.language,
    item.key || "", // If key is empty, insert an empty string
    item.text || "", // If text is empty, insert an empty string
  ]);

  // Add headers to the beginning of the rows array
  const csvContent = [headers, ...rows];

  // Convert to CSV format
  const csvString = csvContent.map((row) => row.join(",")).join("\n");

  return csvString;
};

const Translations = () => {
  const navigate = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);

  const fetchExportsData = async () => {
    try {
      setLoad(true);
      const res = await getAllExport();
      if (res?.status) {
        setExportData(res?.data.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    } finally {
      setLoad(false);
    }
  };

  const handleExport = async () => {
    setLoad(true);
    try {
      await fetchExportsData();
    } catch (error) {
      console.error("Error fetching export data: ", error);
      setLoad(false);
    }
  };

  useEffect(() => {
    if (exportData.length > 0 && !load) {
      const csvString = generateCSV(exportData);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "translations_export.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [exportData, load]);

  return (
    <>
      {load ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/study-settings/translations">
              Translation
            </Link>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Translation</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <div className="flex items-center justify-center gap-10 mb-96">
            <Link to="/study-management/study-settings/translations/import">
              <div className="translationImport">
                <button>Import</button>
              </div>
            </Link>
            <div className="translationExport">
              <button onClick={handleExport}>Export</button>
            </div>
          </div>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back To Create Study
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Translations;