import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  Switch,
  Stack,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import 'react-phone-input-2/lib/style.css';
import {
  registerAdminNew,
  registerUser,
  getSponsorBasedStudy,
} from "../../../services/users";
import { getSponsorImage } from "../../../services/sponsors";
import { getStudyById } from "../../../services/studies";
import {
  getStudyRoles,
  getStudyRoleById,
  assignStudyRoles,
} from "../../../services/study_roles";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Container, Row, Col } from "react-bootstrap";
import RolesSites from "./RolesSites";
import "./User.css";
import DialogBox from "../../../components/DialogBox";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import arrowDown from "../../../assets/svgs/arrow_down.svg";
import { FaCheckCircle } from "react-icons/fa";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  validationSchemaAdmin,
  validationSchemaSponsor,
  validationSchemaStudy,
  validationSchemaUser,
  AntSwitch,
  label,
  BootstrapTooltip,
  TabPanel,
  a11yProps,
  initialStaticStudyRoles,
  initialStudySites,
  allStudiesRemoved,
  noStudyFound,
  notifyAdmin,
  notifyPhoneNumber,
  notifyStudy,
  notifySponsor,
  notifyUser,
  notifyUserNumber,
  reqFailed,
  studyRemoved,
  notifyFirstName,
  notifyEmail,
  notifyEmailVerify,
  checkBoxOne,
} from "./utils";
import SponsorContext from "../../../context/sponsor/SponsorContext";
import axios from "axios";
import { dataServer } from "../../../services/axios.config";
import { rowStyles } from "../StudySetup/Visits/Questionnaires/EditVisitQuestionnaire";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const AddUser = () => {
  const [value, setValue] = React.useState(0);
  const [showRowSite, setShowRowSite] = React.useState("");
  const [load, setLoad] = React.useState(true);
  const [sponsorsData, setSponsorsData] = React.useState([]);
  const [newSponsor, setNewSponsor] = React.useState([]);
  const [sponsorChecked, setSponsorChecked] = React.useState(() =>
    newSponsor.map((i) => false)
  );
  const [checkedSponsorId, setCheckedSponsorId] = React.useState([]);

  const [checkStudy, setCheckStudy] = React.useState([]);
  const [enrolledStudy, setEnrolledStudy] = React.useState([]);

  const [firstName, setFirstName] = React.useState("");
  const [middleName, setMiddleName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [staticPass, setStaticPass] = React.useState("");
  const [isStaticChecked, setIsStaticChecked] = React.useState(false);
  const [isAdminChecked, setIsAdminChecked] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);

  const [adminFirstName, setAdminFirstName] = React.useState("");
  const [adminMiddleName, setAdminMiddleName] = React.useState("");
  const [adminEmail, setAdminEmail] = React.useState("");
  const [adminLastName, setAdminLastName] = React.useState("");
  const [testPhone, setTestPhone] = React.useState("");

  const [testPhoneUser, setTestPhoneUser] = React.useState("");

  const [studyRoles, setStudyRoles] = React.useState([]);
  const [roleUserId, setRoleUserId] = React.useState("");
  const [roleStudyId, setRoleStudyId] = React.useState("");
  const [roleId, setRoleId] = React.useState("");

  const [operationDone, setOperationDone] = React.useState(false);

  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const [callStudyImageApi, setCallStudyImageApi] = React.useState(false);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [activeRole, setActiveRole] = React.useState("");
  const [activeSite, setActiveSite] = React.useState("");
  const [activeArrSites, setActiveArrSites] = React.useState([]);
  const [activeArrStudies, setActiveArrStudies] = React.useState([]);

  const [showRolesActive, setShowRolesActive] = React.useState(false);
  const [activeSponsor, setActiveSponsor] = React.useState("");
  const [activeStudy, setActiveStudy] = React.useState("");

  const [activeArrow, setActiveArrow] = React.useState("");

  const open1 = Boolean(anchorEl1);

  // const [staticStudyRoles, setStaticStudyRoles] = React.useState(
  //   initialStaticStudyRoles
  // );

  const [staticStudyRoles, setStaticStudyRoles] = React.useState([]);

  const [studySites, setStudySites] = React.useState(initialStudySites);

  const [studyRoleSitesObject, setStudyRoleSitesObject] = React.useState([]);
  const [activeStudyRole, setActiveStudyRole] = React.useState(null);

  // const { sponsorsData, setLoading } =
  //   useContext(SponsorContext);

  const rows = staticStudyRoles?.map((row) => ({
    id: row.id,
    name: row.shortName,
    concurrencyStamp: row.isBlinded,
    description: row.description,
  }));

  const rows2 = studySites.map((row) => ({
    id: row.id,
    name: row.name,
    editId: row.editId,
  }));

  const getAllSponsors = (
    pageNumber,
    pageSize,
    sortingType,
    sortingField,
    debouncedSearchString
  ) => {
    return new Promise((resolve, reject) => {
      dataServer
        .get(
          pageNumber && pageSize
            ? `app/Sponsor/all?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"Name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
            : "app/Sponsor/all"
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };

  const fetchSponsors = async () => {
    try {
      const res = await getAllSponsors();
      let activeSponsors = res?.result.filter((item) => item.isActive === true);
      setSponsorsData(activeSponsors);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const getAllStudyRoles = async () => {
    try {
      const resp = await axios.get(
        "https://genesisapi.augursapps.com/app/StudyRoles/GetAllStudyRoles?pageNumber=1&pageSize=100000000"
      );

      if (resp?.data) {
        setStaticStudyRoles(resp?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllStudyRoles();
  }, []);

  const getImage = async () => {
    const temp = [];
    if (sponsorsData.length !== 0) {
      sponsorsData.map(async (item, key) => {
        const res = await getSponsorImage(item.imageUrl);
        const preview = res.data;

        const {
          createdBy,
          dateCreated,
          dateUpdated,
          id,
          imageUrl,
          isActive,
          sponsorName,
          secret,
          studies,
          updatedBy,
        } = item;

        temp.push({
          createdBy,
          dateCreated,
          dateUpdated,
          id,
          imageUrl,
          isActive,
          sponsorName,
          secret,
          studies,
          updatedBy,
          previewImg: `${preview}`,
        });
      });
      setNewSponsor(temp);
      setLoad(false);
    }
  };

  const handleSelectAllSites = (e, sID) => {
    e.preventDefault();
    const temp = rows2.map((site) => site.name);

    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].sites = temp;
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      sites: temp,
    }));
    setActiveArrSites(temp);
  };

  const handleActiveSites = (e, siteName, sID) => {
    e.preventDefault();
    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].sites = [
        ...studyObject[studyIndex].sites,
        siteName,
      ];
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      sites: [...study.sites, siteName],
    }));
  };

  const showRolesAndSites = (e, activeSponsor, activeStudy, sID) => {
    e.preventDefault();

    if (studyRoleSitesObject.some((study) => study.studyId === sID)) {
      setActiveArrow(sID);
      const currentStudyRole = studyRoleSitesObject.find(
        (study) => study.studyId === sID
      );
      if (currentStudyRole && Object.keys(currentStudyRole).length > 0) {
        setActiveStudyRole(currentStudyRole);
      } else {
        setActiveStudyRole({
          studyId: sID,
          study: `${activeSponsor} - ${activeStudy}`,
          roles: activeRole,
          sites: activeArrSites,
        });
      }
    } else {
      setActiveArrStudies([...activeArrStudies, sID]);
      setStudyRoleSitesObject([
        ...studyRoleSitesObject,
        {
          studyId: sID,
          study: `${activeSponsor} - ${activeStudy}`,
          roles: activeRole,
          sites: [],
        },
      ]);
      setActiveStudyRole({
        studyId: sID,
        study: `${activeSponsor} - ${activeStudy}`,
        roles: activeRole,
        sites: [],
      });
      setActiveArrow(sID);
    }
    if (showRowSite === sID) {
      setShowRowSite("");
    } else {
      setShowRowSite(sID);
    }
    setShowRolesActive(true);
    setActiveSponsor(activeSponsor);
    setActiveStudy(activeStudy);
  };

  const getStudyImage = async () => {
    if (checkStudy.length > 0) {
      Promise.allSettled(
        checkStudy.map(async (study) => {
          try {
            const res = await getSponsorImage(study.imageUrl);
            const preview = res.data;

            const {
              createdBy,
              dateCreated,
              dateUpdated,
              id,
              imageUrl,
              isActive,
              name,
              secret,
              studies,
              updatedBy,
            } = study;

            return {
              createdBy,
              dateCreated,
              dateUpdated,
              id,
              imageUrl,
              isActive,
              name,
              secret,
              studies,
              updatedBy,
              previewImg: `${preview}`,
            };
          } catch (err) {
            console.log("Error: ", err);
            return null;
          }
        })
      )
        .then((response) => {
          setEnrolledStudy(response.map(({ value }) => value));
          setCallStudyImageApi(false);
          notifySponsor();
          setValue(2);
          setLoad(false);
        })
        .catch((err) => {
          console.log("Error: ", err);
          setLoad(false);
          setCallStudyImageApi(false);
        });
    } else if (checkStudy.length === 0) {
      setEnrolledStudy([]);
      setLoad(false);
    }
  };

  const [items, setItems] = useState([]);

  const addItem = (newItem) => {
    setItems((prevItems) => {
      // Check if the new item already exists in the array
      if (!prevItems.includes(newItem)) {
        // If not, add the new item
        return [...prevItems, newItem];
      }
      // Otherwise, return the existing array
      return prevItems;
    });
  };

  const roleHandler = (role, sID) => {
    addItem(activeStudyRole?.studyId);
    let studyObject = studyRoleSitesObject;
    let studyIndex = studyObject.findIndex((study) => study.studyId === sID);
    if (studyIndex >= 0) {
      studyObject[studyIndex].roles = role;
      setStudyRoleSitesObject([...studyObject]);
    }
    setActiveStudyRole((study) => ({
      ...study,
      roles: role,
    }));
  };
  const fetchStudyRoles = async () => {
    try {
      setLoad(true);
      const res = await getStudyRoles();
      setStudyRoles(res.data);
    } catch (err) {
      setLoad(false);
    }
  };

  React.useEffect(() => {
    setLoad(true);
    fetchSponsors();
    fetchStudyRoles();
  }, []);

  React.useEffect(() => {
    setLoad(true);
    getImage();
  }, [sponsorsData]);

  React.useEffect(() => {
    if (callStudyImageApi === true) {
      setLoad(true);
      getStudyImage();
    }
  }, [callStudyImageApi]);

  // React.useEffect(() => {
  //   console.log("SHOW DIALOG ==> ", showDialog);
  // }, [showDialog]);

  const navigate = useNavigate();

  const validationSchemaUser = yup.object().shape({
    firstName: yup.string().max(255).required("First name is required"),
    middleName: yup.string().max(255).required("Middle name is required"),
    email: yup.string().email().required("User email is required"),
    lastName: yup.string().max(255).required("Last name is required"),
  });

  const validationSchemaAdmin = yup.object().shape({
    adminFirstName: yup.string().max(255).required("First name is required"),
    adminMiddleName: yup.string().max(255).required("Middle name is required"),
    adminEmail: yup.string().email().required("Admin email is required"),
    adminLastName: yup.string().max(255).required("Last name is required"),
  });

  const validationSchemaSponsor = yup.object().shape({
    checkbox: yup.array().min(1),
  });

  const validationSchemaStudy = yup.object().shape({
    checkboxStudy: yup.array(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchemaUser),
  });

  const {
    register: registerAdmin,
    handleSubmit: handleSubmitAdmin,
    watch: watchAdmin,
    control: adminControl,
    formState: { errors: errorsAdmin },
  } = useForm({
    resolver: yupResolver(validationSchemaAdmin),
  });

  const {
    register: registerSponsor,
    handleSubmit: handleSubmitSponsor,
    watch: watchSponsor,
    formState: { errors: errorsSponsor },
  } = useForm({
    resolver: yupResolver(validationSchemaSponsor),
  });

  const {
    register: registerStudy,
    handleSubmit: handleSubmitStudy,
    watch: watchStudy,
    formState: { errors: errorsStudy },
  } = useForm({
    resolver: yupResolver(validationSchemaStudy),
  });

  const generatePassword = () => {
    var length = 8;
    var pass = "Genesis@123";
    setStaticPass(pass);
    return pass;
  };

  const cancelAddUser = (e) => {
    e.preventDefault();
    // if (window.confirm("Changes you made will not be saved. Are you sure?")) {
    navigate("/all-users");
    // }
  };

  const cancelUserSponsor = (e) => {
    e.preventDefault();
    // setValue(0);
    // if (window.confirm("Changes you made will not be saved. Are you sure?")) {
    navigate("/all-users");
    // }
  };

  const cancelUserStudy = (e) => {
    e.preventDefault();
    // setValue(1);
    // if (window.confirm("Changes you made will not be saved. Are you sure?")) {
    navigate("/all-users");
    // }
  };

  const onSubmitUser = async () => {
    setLoad(true);
    // e.preventDefault();
    if (testPhoneUser === "") {
      setLoad(false);
      notifyUserNumber();
    } else {
      try {
        // const firstName = data.firstName;
        // const middleName = data.middleName;
        // const email = data.email;
        // const lastName = data.lastName;
        const userData = {
          firstName,
          middleName,
          email,
          lastName,
          phoneNumber: testPhoneUser,
          password: isStaticChecked ? staticPass : "",
          isAdmin: false,
          isActive: isActive,
          staticPass: isStaticChecked,
          selectedStudies: items,
        };

        setLoad(true);

        const res = await registerUser(userData);

        // setFormValues(data);
        if (res.status) {
          setLoad(false);
          setRoleUserId(res.data.result);
          // notifyUser();
          // setValue(1);
        } else {
          setLoad(false);
          reqFailed();
        }
      } catch (err) {
        setLoad(false);
        console.log("submit error: ", err);
        reqFailed();
      }
    }
  };

  const onSubmitAdmin = async (data, e) => {
    setLoad(true);
    // e.preventDefault();
    if (testPhoneUser === "") {
      setLoad(false);
      notifyPhoneNumber();
    } else {
      try {
        // const firstName = data.adminFirstName;
        // const middleName = data.adminMiddleName;
        // const email = data.adminEmail;
        // const lastName = data.adminLastName;
        const adminData = {
          firstName,
          middleName,
          email,
          lastName,
          phoneNumber: testPhoneUser,
          password: isStaticChecked ? staticPass : "",
          isAdmin: isAdminChecked,
          isActive: isActive,
          staticPass: isStaticChecked,
          selectedStudies: items,
        };

        const res = await registerAdminNew(adminData);

        if (res.status) {
          setLoad(false);
          setRoleUserId(res.data.result);
          // notifyAdmin();
          // setValue(1);
        }
      } catch (err) {
        setLoad(false);
        console.log("submit error: ", err);
      }
    }
  };

  const onSubmitSponsor = async (data, e) => {
    e.preventDefault();
    try {
      // setFormValues(data);
      await submitCheckedSponsors();
    } catch (err) {
      console.log("submit error: ", err);
    }
  };

  const onSubmitStudy = (data, e) => {
    e.preventDefault();
    setLoad(true);
    try {
      isAdminChecked ? onSubmitAdmin() : onSubmitUser();
      setShowDialog(false);
      setTimeout(() => {
        setShowDialog(false);
        notifyStudy();
        navigate("/all-users");
        setLoad(false);
      }, 1000);
    } catch (err) {
      console.log("submit error: ", err);
    }
  };

  const handleFirstNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setFirstName(name);
    if (name) {
      setShowDialog(true);
      clearErrors("firstName");
    }
  };

  const handleMiddleNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setMiddleName(name);
    if (name) {
      setShowDialog(true);
      clearErrors("middleName");
    }
  };

  const handleLastNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setLastName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleEmailChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setEmail(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminFirstNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminFirstName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminMiddleNameChange = (event) => {
    setAdminMiddleName(event.target.value);
    setShowDialog(true);
  };

  const handleAdminLastNameChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminLastName(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleAdminEmailChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setAdminEmail(name);
    if (name) {
      setShowDialog(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStaticCheck = () => {
    generatePassword();
    setIsStaticChecked(!isStaticChecked);
    setShowDialog(true);
  };

  const handleAdminCheck = () => {
    setIsAdminChecked((prevState) => {
      const newAdminChecked = !prevState;

      if (newAdminChecked) {
        setAdminFirstName(firstName);
        setAdminMiddleName(middleName);
        setAdminEmail(email);
        setAdminLastName(lastName);
        setTestPhoneUser(testPhoneUser);
        setStaticPass(staticPass);
      } else {
        setFirstName(adminFirstName);
        setMiddleName(adminMiddleName);
        setEmail(adminEmail);
        setLastName(adminLastName);
        setTestPhoneUser(testPhoneUser);
        setStaticPass(staticPass);
      }

      return newAdminChecked;
    });

    setShowDialog(true);
  };

  const handleIsActive = () => {
    setIsActive(!isActive);
    setShowDialog(true);
  };

  const onPhoneChange = (value) => {
    setTestPhone(value);
  };

  const onPhoneUserChange = (value) => {
    setTestPhoneUser(value);
    setShowDialog(true);
  };

  const handleSaveCheck = (item, event, index) => {
    if (checkedSponsorId.length === 0) {
      return false;
    } else {
      const array = checkedSponsorId.filter((check) => check === item);
      if (array) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSponsorId = async (item, event, index) => {
    setShowDialog(true);
    try {
      const isChecked = event.target.checked;
      setSponsorChecked((sponsorChecked) => {
        return sponsorChecked.map((c, i) => {
          if (i === index) return isChecked;
          return c;
        });
      });
      if (isChecked) {
        const id = item;

        if (checkedSponsorId.includes(id)) {
          setCheckedSponsorId(
            checkedSponsorId.filter((sponsor) => sponsor !== id)
          );
          const filterSponsors = checkStudy.filter((study) => study.id !== id);
          setCheckStudy(filterSponsors);
          return true;
        }

        const checkedArray = [];
        checkedArray.push(id);

        setCheckedSponsorId([...checkedSponsorId, ...checkedArray]);
      } else {
        setLoad(true);
        const id = item;

        const filterChecked = checkedSponsorId.filter(
          (sponsor) => sponsor !== id
        );

        setCheckedSponsorId(
          checkedSponsorId.filter((sponsor) => sponsor !== id)
        );
        const filterSponsors = checkStudy.filter((study) => study.id !== id);
        setCheckStudy(filterSponsors);
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err);
      noStudyFound();
    }
  };

  const submitCheckedSponsors = async () => {
    try {
      setLoad(true);
      const responses = await Promise.all(
        checkedSponsorId.map(async (id) => {
          const res = await getSponsorBasedStudy(id);
          if (res.status) {
            return res.data;
          } else {
            return null;
          }
        })
      );
      if (responses.length) {
        setCheckStudy(responses);
        setCallStudyImageApi(true);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const isDisabled = (tabIndex) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };

  React.useEffect(() => {
    setActiveRole("");
  }, [showRowSite]);

  const tabStyles = {
    fontSize: "12px",
    fontWeight: "700",
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />

          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link>
            {" > "}
            <Link to="/all-users">All Users</Link>
            {" > "}
            <span
              style={{
                color: "#4b8ac0",
                cursor: "pointer",
              }}
            >
              Add User
            </span>
          </p>
          <Box sx={{ marginTop: "2%" }}>
            <Box sx={{ width: "27%", borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                {/* <Tab label="User" {...a11yProps(0)} />
                <Tab label="Sponsors" {...a11yProps(1)} />
                <Tab label="Studies" {...a11yProps(2)} /> */}
                <Tab
                  sx={tabStyles}
                  label="User"
                  {...a11yProps(0)}
                  disabled={isDisabled(0)}
                />
                <Tab
                  sx={tabStyles}
                  label="Sponsors"
                  {...a11yProps(1)}
                  disabled={isDisabled(1)}
                />
                <Tab
                  sx={tabStyles}
                  label="Studies"
                  {...a11yProps(2)}
                  disabled={isDisabled(2)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {isAdminChecked ? (
                <>
                  <p className="user-heading">User (Admin)</p>
                  <form>
                    <div className="userForm-body">
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">First Name *</label>

                          <input
                            className="nameField"
                            defaultValue={adminFirstName}
                            type="text"
                            name="adminFirstName"
                            {...registerAdmin("adminFirstName", {
                              required: true,
                            })}
                            onChange={handleAdminFirstNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminFirstName?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Middle Name </label>

                          <input
                            className="nameField"
                            defaultValue={adminMiddleName}
                            type="text"
                            name="adminMiddleName"
                            {...registerAdmin("adminMiddleName", {
                              required: true,
                            })}
                            onChange={handleMiddleNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminMiddleName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">Email *</label>

                          <input
                            className="nameField"
                            defaultValue={adminEmail}
                            type="text"
                            name="adminEmail"
                            {...registerAdmin("adminEmail", { required: true })}
                            onChange={handleAdminEmailChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminEmail?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Last Name</label>

                          <input
                            className="nameField"
                            defaultValue={adminLastName}
                            type="text"
                            name="adminLastName"
                            {...registerAdmin("adminLastName", {
                              required: true,
                            })}
                            onChange={handleAdminLastNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errorsAdmin.adminLastName?.message}
                          </span>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">
                            Phone Number *
                          </label>
                          <Controller
                            render={({ field }) => (
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                inputProps={{
                                  name: "testPhoneUser",
                                  required: true,
                                }}
                                name="testPhoneUser"
                                defaultValue={testPhoneUser}
                                onChange={onPhoneUserChange}
                              />
                            )}
                            name="testPhoneUser"
                            control={control}
                          />
                          {/* <span
                  style={{
                    color: "#3661eb",
                    marginTop: "1%",
                    fontSize: "12px",
                  }}
                >
                  {errors.testPhoneUser?.message}
                </span> */}
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isAdminChecked}
                                    onChange={handleAdminCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Admin</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isStaticChecked}
                                    onChange={handleStaticCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">
                                    Static Password
                                  </div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isActive}
                                    onChange={handleIsActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Active</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={9}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={4}>
                          {isStaticChecked ? (
                            <p>Static Password: {staticPass}</p>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}></Col>
                        <Col md={3}></Col>
                      </Row>
                      <Row style={{ marginTop: "3%" }}>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-cancel-btn"
                              onClick={cancelAddUser}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                        <Col md={2}></Col>
                        <div className="study-management-head-end">
                          <button
                            className="study-management-create-btn-md"
                            type="button"
                            onClick={() => {
                              const regex =
                                /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

                              if (adminFirstName === "") {
                                notifyFirstName();
                                setLoad(false);
                              } else if (adminEmail === "") {
                                notifyEmail();
                                setLoad(false);
                              } else if (!regex.test(adminEmail)) {
                                notifyEmailVerify();
                                setLoad(false);
                              } else if (
                                isAdminChecked &&
                                testPhoneUser === ""
                              ) {
                                notifyUserNumber();
                                setLoad(false);
                              } else {
                                notifyAdmin();
                                setValue(1);
                                setLoad(false);
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </Row>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <p className="user-heading">User (Non Admin)</p>
                  <form>
                    <div className="userForm-body">
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">First Name *</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={firstName}
                            type="text"
                            name="firstName"
                            {...register("firstName", { required: true })}
                            onChange={handleFirstNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.firstName?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Middle Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={middleName}
                            type="text"
                            name="middleName"
                            {...register("middleName", { required: true })}
                            onChange={handleMiddleNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.middleName?.message}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">Email *</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={email}
                            type="text"
                            name="email"
                            {...register("email", { required: true })}
                            onChange={handleEmailChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.email?.message}
                          </span>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <label className="userInputLabel">Last Name</label>

                          <input
                            className="nameSponsorField"
                            defaultValue={lastName}
                            type="text"
                            name="lastName"
                            {...register("lastName", { required: true })}
                            onChange={handleLastNameChange}
                          />
                          <span
                            style={{
                              color: "#3661eb",
                              marginTop: "1%",
                              fontSize: "12px",
                            }}
                          >
                            {errors.lastName?.message}
                          </span>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "2%" }}>
                        <Col md={5}>
                          <label className="userInputLabel">
                            Phone Number *
                          </label>
                          <Controller
                            render={({ field }) => (
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                inputProps={{
                                  name: "testPhoneUser",
                                  required: true,
                                }}
                                name="testPhoneUser"
                                onChange={onPhoneUserChange}
                                defaultValue={testPhoneUser}
                              />
                            )}
                            name="testPhoneUser"
                            control={control}
                          />
                          {/* <span
                  style={{
                    color: "#3661eb",
                    marginTop: "1%",
                    fontSize: "12px",
                  }}
                >
                  {errors.testPhoneUser?.message}
                </span> */}
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isAdminChecked}
                                    onChange={handleAdminCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Admin</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={4}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isStaticChecked}
                                    onChange={handleStaticCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">
                                    Static Password
                                  </div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <div className="userToggleContainer">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isActive}
                                    onChange={handleIsActive}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className="userText">Active</div>
                                </Stack>
                              </div>
                            </Col>
                            <Col md={9}></Col>
                          </Row>
                        </Col>
                        <Col md={6}></Col>
                      </Row>
                      <Row style={{ marginTop: "2%" }}>
                        <Col md={4}>
                          {isStaticChecked ? (
                            <p>Static Password: {staticPass}</p>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}></Col>
                        <Col md={3}></Col>
                      </Row>
                      <Row style={rowStyles}>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-cancel-btn"
                              onClick={cancelAddUser}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-create-btn-md"
                              type="button"
                              onClick={() => {
                                const regex =
                                  /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

                                if (firstName === "") {
                                  notifyFirstName();
                                  setLoad(false);
                                } else if (email === "") {
                                  notifyEmail();
                                  setLoad(false);
                                } else if (!regex.test(email)) {
                                  notifyEmailVerify();
                                  setLoad(false);
                                } else if (
                                  !isAdminChecked &&
                                  testPhoneUser === ""
                                ) {
                                  notifyUserNumber();
                                  setLoad(false);
                                } else {
                                  notifyUser();
                                  setValue(1);
                                  setLoad(false);
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <p className="user-heading">Sponsors (Active)</p>
              {/* {load ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70vh",
                    }}
                  >
                    <BeatLoader color="#3661eb" />
                  </div>
                </>
              ) : ( */}
              <form onSubmit={handleSubmitSponsor(onSubmitSponsor)}>
                <div className="sponsor-cols">
                  {newSponsor?.map((item, index) => (
                    <div key={item?.id} className="sponsorSelectBody">
                      <div className="sponsorBodyUser">
                        <Checkbox
                          {...label}
                          checked={checkedSponsorId?.includes(item?.id)}
                          {...registerSponsor("checkbox", { required: true })}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 24,
                              color: "#3661eb",
                            },
                          }}
                          // defaultChecked={(event) => handleSaveCheck(item.id, event, index)}
                          onChange={(event) =>
                            handleSponsorId(item.id, event, index)
                          }
                        />
                        <img
                          onClick={() =>
                            // Simulating a checkbox toggle when the image is clicked
                            handleSponsorId(
                              item.id,
                              {
                                target: {
                                  checked: !checkedSponsorId?.includes(
                                    item?.id
                                  ),
                                },
                              },
                              index
                            )
                          }
                          src={item.previewImg}
                          className="sponsorImageUser"
                        />
                        <label
                          className="sponsorSubUser"
                          onClick={() =>
                            // Simulating a checkbox toggle when the image is clicked
                            handleSponsorId(
                              item.id,
                              {
                                target: {
                                  checked: !checkedSponsorId?.includes(
                                    item?.id
                                  ),
                                },
                              },
                              index
                            )
                          }
                        >
                          {item.sponsorName}
                        </label>
                      </div>
                      {/* <span
                        style={{
                          color: "#3661eb",

                          fontSize: "12px",
                        }}
                      >
                        {errorsSponsor.checkbox?.message
                          ? "Atleast one sponsor is required"
                          : ""}
                      </span> */}
                    </div>
                  ))}
                </div>
                <Row style={{ marginTop: "3%" }}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-cancel-btn"
                        onClick={cancelUserSponsor}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        className="study-management-create-btn-md"
                        type="submit"
                        onClick={() => {
                          if (checkedSponsorId == 0) {
                            checkBoxOne();
                            setLoad(false);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
              {/* )} */}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* <div className="sponsor-cols-studies">
                {enrolledStudy?.map((item, index) => (
                  <div className="sponsorBodyUser">
                    <img src={item?.previewImg} className="sponsorImageUser" />
                    <div className="userTextContainer">
                      <p className="sponsorSubUser">{item?.name}</p>
                    </div>
                  </div>
                ))}
              </div> */}
              <div>
                <p
                  className="user-heading"
                  style={{ textDecoration: "underline" }}
                >
                  Enrolled Studies
                </p>
                <form onSubmit={handleSubmitStudy(onSubmitStudy)}>
                  <div
                    className="sponsor-cols-studies"
                    style={{ marginLeft: "18px", display: "flex" }}
                  >
                    <Row>
                      <Col md={3}>
                        {enrolledStudy?.map((item, index) => (
                          <Row className="sponsorBodyUserStudy" key={item.id}>
                            <Col>
                              {item.studies.length === 0 ? (
                                <div style={{ fontWeight: "700" }}>
                                  {/* {item?.name} Study not found */}
                                </div>
                              ) : (
                                <>
                                  {!!item.studies.length &&
                                    item.studies.map((study, key1) => (
                                      <>
                                        <RolesSites
                                          study={study}
                                          key1={key1}
                                          roleUserId={roleUserId}
                                          sponsorName={item?.name}
                                          sponsorImage={item?.previewImg}
                                          showRolesActive={showRowSite}
                                          showRolesAndSites={showRolesAndSites}
                                          activeArrow={activeArrow}
                                        />
                                      </>
                                    ))}
                                </>
                              )}
                            </Col>
                          </Row>
                        ))}
                      </Col>
                      <Col md={1}></Col>
                      {activeStudyRole ? (
                        <>
                          <Col md={3}>
                            <div className="sponsorUserRolesContainer">
                              <div className="sponsorUserRolesHeading">
                                <h4>
                                  {activeSponsor} - {activeStudy} Role
                                </h4>
                              </div>
                              {rows.map((role, index) => (
                                <div
                                  className="sponsorBodyUserRoles"
                                  key={index}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h5 style={{ color: "#fff" }}>
                                          {role.description}
                                        </h5>
                                      </div>
                                    }
                                    placement="right"
                                  >
                                    <div
                                      className={`roleName ${
                                        activeStudyRole.roles === role.name &&
                                        "activeRole"
                                      }`}
                                      onClick={() =>
                                        roleHandler(
                                          role.name,
                                          activeStudyRole.studyId
                                        )
                                      }
                                    >
                                      <p className="rolePTag">{role.name}</p>
                                      {role.name === activeStudyRole.roles && (
                                        <span className="checkIcon">
                                          <FaCheckCircle
                                            style={{
                                              fontSize: "20px",
                                              color: "blue",
                                            }}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </BootstrapTooltip>
                                </div>
                              ))}
                            </div>
                          </Col>
                          <Col md={1}></Col>
                          <Col md={3}>
                            <div className="sponsorUserSitesContainer">
                              <div className="sponsorUserSitesHeading">
                                <h4>
                                  {activeSponsor} - {activeStudy} Sites
                                </h4>
                                <div className="selectAllBtnContainer">
                                  <button
                                    className="selectAllBtn"
                                    onClick={(e) =>
                                      handleSelectAllSites(
                                        e,
                                        activeStudyRole.studyId
                                      )
                                    }
                                  >
                                    Select All Sites
                                  </button>
                                </div>
                              </div>
                              {rows2.map((site, index) => (
                                <div
                                  className="sponsorBodyUserSites"
                                  key={index}
                                >
                                  <div
                                    className={`siteName ${
                                      activeStudyRole?.sites?.includes(
                                        site.name
                                      ) && "activeSite"
                                    }`}
                                    onClick={(e) =>
                                      handleActiveSites(
                                        e,
                                        site.name,
                                        activeStudyRole.studyId
                                      )
                                    }
                                  >
                                    <p className="sitePTag">{site.name}</p>

                                    {activeStudyRole?.sites?.includes(
                                      site.name
                                    ) && (
                                      <span className="checkIcon">
                                        <FaCheckCircle
                                          style={{
                                            fontSize: "20px",
                                            color: "blue",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                          <Col md={1}></Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>
                  <Row style={{ marginTop: "3%" }}>
                    <Col md={8}></Col>
                    <Col md={2}>
                      <div className="study-management-head-end">
                        <button
                          className="study-management-cancel-btn"
                          onClick={cancelUserStudy}
                        >
                          Cancel
                        </button>
                        <Col md={2}>
                          <div className="study-management-head-end">
                            <button
                              className="study-management-create-btn-md"
                              type="submit"
                            >
                              Add
                            </button>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </TabPanel>
          </Box>
        </div>
      )}
    </>
  );
};

export default AddUser;
