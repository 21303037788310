import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import JoditEditor from "jodit-react";
import { convert } from "html-to-text";

const ReportBuilder = () => {
  const [selectedSection, setSelectedSection] = useState("Header");
  const [sections, setSections] = useState(["Header"]);
  const [content, setContent] = useState({
    Header: "",
    Content: "",
    Footer: "",
  });


  const addSection = (type) => {
    if (!sections.includes(type)) {
      setSections([...sections, type]);
    }
    setSelectedSection(type);
  };


  const handleContentChange = (value) => {
    setContent({ ...content, [selectedSection]: value });
  };


  const renderText = (htmlContent) => {
    return convert(htmlContent, { wordwrap: 130 });
  };

  const ReportDocument = () => (
    <Document>
      <Page size="A4" style={{ padding: 20 }}>
        {sections.includes("Header") && (
          <View fixed style={{ marginBottom: 10, textAlign: "center" }}>
            <Text style={{ fontSize: 12 }}>{renderText(content.Header)}</Text>
          </View>
        )}

        <View style={{ flexGrow: 1 }}>
          {sections.includes("Content") && (
            <View style={{ marginBottom: 15 }}>
              <Text style={{ fontSize: 12 }}>{renderText(content.Content)}</Text>
            </View>
          )}
        </View>

        {sections.includes("Footer") && (
          <View fixed style={{ bottom: 20, textAlign: "center" }}>
            <Text style={{ fontSize: 12 }}>{renderText(content.Footer)}</Text>
          </View>
        )}
      </Page>
    </Document>
  );

  return (
    <div className="flex gap-4 p-4">
      <div className="w-3/5 p-4 bg-gray-100 rounded-md">
      <h3 className="font-semibold">Report Layout</h3>

        <div className="flex gap-2 mb-4">
          <button
            onClick={() => addSection("Header")}
            className={`px-4 py-2 rounded ${
              selectedSection === "Header" ? "bg-blue-800 text-white" : "bg-blue-600 text-white"
            }`}
          >
            Add Header
          </button>
          <button
            onClick={() => addSection("Content")}
            className={`px-4 py-2 rounded ${
              selectedSection === "Content" ? "bg-blue-800 text-white" : "bg-blue-600 text-white"
            }`}
          >
            Add Content
          </button>
          <button
            onClick={() => addSection("Footer")}
            className={`px-4 py-2 rounded ${
              selectedSection === "Footer" ? "bg-blue-800 text-white" : "bg-blue-600 text-white"
            }`}
          >
            Add Footer
          </button>
        </div>

        <div className="mb-4">
          <h3 className="text-2xl font-semibold">{selectedSection}</h3>
          <JoditEditor
            value={content[selectedSection]}
            onBlur={handleContentChange}
          />
        </div>
      </div>

      <div className="w-2/5">
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          <ReportDocument />
        </PDFViewer>
        <br />
        <PDFDownloadLink document={<ReportDocument />} fileName="report.pdf">
          {({ loading }) => (loading ? "Generating PDF..." : "Download PDF")}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default ReportBuilder;
