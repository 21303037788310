import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import {
  createEditTransaltionService,
  getActiveLanguagesServices,
  getEditorTranslationsServices,
} from "../../Questionnaire";
import RichTextEditor from "../../../../../../components/RichTextEditor";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const MangeTranslation = () => {
  const [languages, setLanguages] = useState([]);
  const [load, setLoad] = useState(false);
  const [translationLanguages, setTranslationLanguages] = useState({});
  const [translationData, setTranslationData] = useState([]);
  const location = useLocation();
  const { fieldId, questionDataId } = location.state || {};

  useEffect(() => {
    getLanguagesData();
    if (fieldId) {
      fetchTranslationsEditorData(fieldId);
    }
  }, [fieldId]);

  const getLanguagesData = async () => {
    setLoad(true);
    try {
      const res = await getActiveLanguagesServices();
      if (res?.status) {
        setLanguages(res?.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err?.message);
    } finally {
      setLoad(false);
    }
  };

  const handleTranslationLanguage = (value, language) => {
    setTranslationLanguages((prevTranslationLanguages) => ({
      ...prevTranslationLanguages,
      [language]: value,
    }));
  };

  const fetchTranslationsEditorData = async (fieldId) => {
    try {
      setLoad(true);
      const res = await getEditorTranslationsServices(fieldId);
      if (res?.status) {
        setTranslationData(res?.data.result);
        const updatedTranslationLanguages = {};

        res?.data.result.forEach((translation) => {
          updatedTranslationLanguages[translation.culturE_NAME] =
            translation.resourcE_VALUE;
        });

        setTranslationLanguages(updatedTranslationLanguages);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    } finally {
      setLoad(false);
    }
  };

  const notify = () =>
    toast.success("Save Successful", {
      toastId: "form-creation",
    });

  const handleSave = async () => {
    try {
      const data = languages.map((item) => {
        const translation = translationData.find(
          (t) => t.culturE_NAME === item.cultureName
        );

        return {
          languageId: item.id,
          key: translation ? translation.resourcE_NAME : null,
          language: item.cultureName,
          text: translationLanguages[item.cultureName] || "",
          description: "string",
        };
      });

      const res = await createEditTransaltionService(data, fieldId);

      if (res?.status) {
        setLoad(false);
        if (fieldId) {
          fetchTranslationsEditorData(fieldId);
        }
        notify();
      } else {
        console.error("Failed to send data", res);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Translation Editor
      </Typography>
      <Divider />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Translation Languages:
      </Typography>
      {languages.map((item, index) => (
        <Box key={index} sx={{ marginBottom: 10, width: "50%" }}>
          <Typography variant="h5">{item?.languageDisplay}</Typography>
          <RichTextEditor
            value={translationLanguages[item.cultureName] || ""}
            onChange={(e) => {
              handleTranslationLanguage(e, item.cultureName);
            }}
            className="mt-6"
          />
        </Box>
      ))}
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="error"
          onClick={() => {
            setTranslationLanguages({});
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default MangeTranslation;