import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Chip, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import Downshift from "downshift";

const useStyles = makeStyles({
    chip: {
        margin: "5px !important",
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap", // Allows chips to wrap to the next line when necessary
        gap: "5px", // Adds spacing between chips
        maxWidth: "100%", // Ensures the container doesn't exceed the TextField width
    },
});

export default function TagsInput({ ...props }) {
    const classes = useStyles();
    const { selectedTags, placeholder, tags, setKeywords, value, ...other } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState([]);

    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);

    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    function handleKeyDown(event) {
        if (event.key === " ") {
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            newSelectedItem.push(event.target.value.trim());
            setSelectedItem(newSelectedItem);
            setInputValue("");
        }
        if (
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        }
    }

    function handleChange(event, item) {
        event.preventDefault();
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = (item) => () => {
        const newSelectedItem = selectedItem.filter((keyword) => keyword !== item);
        setSelectedItem(newSelectedItem);
        setKeywords(newSelectedItem);
    };

    function handleInputChange(event) {
        event.preventDefault();
        setInputValue(event.target.value);
    }

    const textBoxStyles = {
        fontSize: 15,
        width: "79px",
        height: "6px",
    };

    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={(event) => handleChange(event)}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder: selectedItem.length === 0 ? placeholder : "", // Conditional placeholder
                    });
                    return (
                        <div className="tagsInput">
                            <TextField
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    maxWidth: "740px",
                                    width: "740px",
                                }}
                                inputProps={{ style: textBoxStyles }}
                                InputProps={{
                                    startAdornment: (
                                        <Box className={classes.chipsContainer}>
                                            {selectedItem.map((item) => (
                                                <Chip
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                    className={classes.chip}
                                                    onDelete={handleDelete(item)}
                                                    sx={{
                                                        backgroundColor: "gold",
                                                        color: "black",
                                                        borderRadius: "unset",
                                                        margin: 0,
                                                        border: "1px solid black",
                                                        fontSize: "11px"
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    ),
                                    onBlur,
                                    onChange: (event) => {
                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus,
                                }}
                                variant="outlined"
                                label={""}
                                placeholder={selectedItem.length === 0 ? placeholder : ""} // Conditional placeholder
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}

TagsInput.defaultProps = {
    tags: [],
};

TagsInput.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
};