import React, { useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../StudySetup.css";
import RichTextEditor from "react-rte";
import "./EmailBuilder.css";
import TagsInput from "./TagsInput";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";

import {
  editEmailContent,
  getAllEmailTypeData,
  getAllKeyWordsData,
  getEmailContentById,
  getGetPatientStatusTypesData,
} from "../../../../../services/email_builder";
import JoditEditor from "jodit-react";
import { FaSearch } from "react-icons/fa";

const EditEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
    const editorKeyWords = useRef(null);
  
  const id = location.state.id;

  const [load, setLoad] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [editorValue, setEditorValue] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [focusedState, setFocusedState] = React.useState("emailSubject");

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [emailKeywordState, setEmailKeywordState] = React.useState("");
  const [emailTypeValue, setEmailTypeValue] = React.useState();
  const [patientTypeValue, setPatientTypeValue] = React.useState();
  const [allKeyWords, setAllKeyWords] = React.useState([]);

  const [isBlindedState, setIsBlindedState] = React.useState(false);
  const [isSiteSpecificState, setIsSiteSpecificState] = React.useState(false);
  const [sendToCreatorState, setSendToCreatorState] = React.useState(false);
  const [displayOnScreenState, setDisplayOnScreenState] = React.useState(false);
  const [emailTypeOptions, setEmailTypeOptions] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  const [patientStatusTypeOptions, setPatientStatusTypeOptions] =
    React.useState([]);

  const editEmailSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    notes: yup.string().required("This field is required"),
    isBlinded: yup.bool().oneOf([true, false], "This field is required"),
    isSiteSpecific: yup.bool().oneOf([true, false], "This field is required"),
    sendToCreator: yup.bool().oneOf([true, false], "This field is required"),
    displayOnScreen: yup.bool().oneOf([true, false], "This field is required"),
    patientStatusTypeId: yup.string().required("This field is required"),
    emailType: yup.string().required("This field is required"),
    // emailSubject: yup.string().required("This field is required"),
    keywords: yup.string().required("This field is required"),
    emailBody: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editEmailSchema),
  });

  const notify = () =>
    toast.success("Email Edited Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  React.useEffect(() => {
    fetchAllEmailTypeData();
    fetchAllPatientStatusTypeIdData();
    fetchAllKeywordsData();
  }, []);

  const handleClick = (fieldName) => {
    setFocusedState(fieldName);
    // console.log("User clicked on:", fieldName);
  };

  const fetchAllEmailTypeData = async () => {
    try {
      const res = await getAllEmailTypeData();
      if (res.status) {
        setEmailTypeOptions(res?.data.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const fetchAllKeywordsData = async () => {
    setLoad(true);
    try {
      const res = await getAllKeyWordsData();

      if (res.status) {
        setAllKeyWords(res?.data.result);
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const fetchAllPatientStatusTypeIdData = async () => {
    try {
      const res = await getGetPatientStatusTypesData();
      if (res.status) {
        setPatientStatusTypeOptions(res?.data.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const fetchEmailById = async () => {
    try {
      setLoad(true);
      const res = await getEmailContentById(id);

      if (res.status) {
        setLoad(false);
        const {
          name,
          notes,
          isBlinded,
          isSiteSpecific,
          isEmailSentToPerformingUser: sendToCreator,
          displayOnScreen,
          patientStatusTypeId,
          emailContentTypeId: emailType,
          subjectLineTemplate: keywords,
          bodyTemplate: emailBody,
        } = res?.data.result;

        setIsBlindedState(isBlinded);
        setIsSiteSpecificState(isSiteSpecific);
        setSendToCreatorState(sendToCreator);
        setDisplayOnScreenState(displayOnScreen);
        setEmailTypeValue(emailType);
        setPatientTypeValue(patientStatusTypeId);
        setEmailKeywordState(keywords);
        setKeywords(keywords);
        reset({
          name,
          notes,
          isBlinded,
          isSiteSpecific,
          sendToCreator,
          displayOnScreen,
          patientStatusTypeId,
          keywords,
          emailBody,
        });
        setEditorValue(emailBody);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const addKeyword = (keyword) => {
    if (focusedState === "emailSubject") {
      addKeywordToSubject(keyword);
    } else if (focusedState === "emailEditor") {
      addKeywordToEditor(keyword);
    }
  };

  const addKeywordToSubject = (keyword) => {
    const editorInstance = editorKeyWords?.current;

    if (!editorInstance || !editorInstance.editor) {
      console.error("JoditEditor instance is not available yet.");
      return;
    }

    const editor = editorInstance.editor;
    editor.focus();

    setTimeout(() => {
      const selection = window.getSelection();

      if (!selection || selection.rangeCount === 0) {
        console.error("No selection range available.");
        return;
      }

      const range = selection.getRangeAt(0);
      const wrapperDiv = document.createElement("span");
      wrapperDiv.className = "inline-flex items-center space-x-1";

      const keywordSpan = document.createElement("span");
      keywordSpan.className =
        "border border-yellow-500 bg-yellow-100 text-black px-2 py-1 rounded inline-block mx-2";
      keywordSpan.textContent = keyword;
      // keywordSpan.setAttribute("contenteditable", "false");

      const beforeSpace = document.createTextNode("\u200B");
      const afterSpace = document.createTextNode("\u200B");

      wrapperDiv.appendChild(beforeSpace);
      wrapperDiv.appendChild(keywordSpan);
      wrapperDiv.appendChild(afterSpace);

      range.deleteContents();
      range.insertNode(wrapperDiv);

      range.setStartAfter(wrapperDiv);
      range.setEndAfter(wrapperDiv);
      selection.removeAllRanges();
      selection.addRange(range);

      const newValue = editor.innerHTML;
      setEmailKeywordState(newValue);
      setValue("keywords", newValue, { shouldValidate: true });
    }, 100);
  };

  const addKeywordToEditor = (keyword) => {
    const editorInstance = editorRef?.current;

    if (!editorInstance || !editorInstance.editor) {
      console.error("JoditEditor instance is not available yet.");
      return;
    }

    const editor = editorInstance.editor;
    editor.focus();

    setTimeout(() => {
      const selection = window.getSelection();

      if (!selection || selection.rangeCount === 0) {
        console.error("No selection range available.");
        return;
      }

      const range = selection.getRangeAt(0);

      // Create a wrapper div to allow placing the cursor before or after
      const wrapperDiv = document.createElement("span");
      wrapperDiv.className = "inline-flex items-center space-x-1";

      // Create span for the keyword with `contenteditable=false`
      const keywordSpan = document.createElement("span");
      keywordSpan.className =
        "keyword border border-yellow-500 bg-yellow-100 text-black px-2 py-1 rounded inline-block mx-2";
      keywordSpan.textContent = keyword;
      keywordSpan.setAttribute("contenteditable", "false");

      const beforeSpace = document.createTextNode("\u200B");
      const afterSpace = document.createTextNode("\u200B");

      wrapperDiv.appendChild(beforeSpace);
      wrapperDiv.appendChild(keywordSpan);
      wrapperDiv.appendChild(afterSpace);

      range.deleteContents();
      range.insertNode(wrapperDiv);

      // Move the cursor outside the span
      range.setStartAfter(wrapperDiv);
      range.setEndAfter(wrapperDiv);
      selection.removeAllRanges();
      selection.addRange(range);

      // Update the editor content
      const newValue = editor.innerHTML;
      setEditorValue(newValue);
      setValue("emailBody", newValue, { shouldValidate: true });
    }, 100);
  };

  React.useEffect(() => {
    fetchEmailById(id);
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);

      const {
        name,
        notes,
        isBlinded,
        isSiteSpecific,
        sendToCreator,
        displayOnScreen,
        patientStatusTypeId,
        keywords,
        emailBody,
        emailType,
      } = data;

      const emailBodyNew = emailBody;

      const newData = {
        id,
        name,
        notes,
        isBlinded,
        isSiteSpecific,
        displayOnScreen,
        patientStatusTypeId,
        subjectLineTemplate: keywords,
        translationKey: "string",
        bodyTemplate: emailBodyNew,
        emailContentTypeId: emailType,
        isEmailSentToPerformingUser: sendToCreator,
      };

      const res = await editEmailContent(id, newData);

      if (res.status) {
        setLoad(false);
        fetchEmailById(id);
        notify();
        navigate("/study-management/study-settings/email-builder");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  function onChange(value) {
    setEditorValue(value);
    setValue("emailBody", value.toString("html"), {
      shouldValidate: true,
    });
  }

  function onKeywordChange(value) {
    if (!value) return;
    setEmailKeywordState(value);
    setValue("keywords", value.toString?.("html") || value, {
      shouldValidate: true,
    });
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  function handleSelecetedTags(items) {
    // console.log(items);
    setEmailKeywordState(items);
    return items;
  }

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/study-settings/email-builder");
  };

  // React.useEffect(() => {
  //   setValue("keywords", emailKeywordState, {
  //     shouldValidate: true,
  //   });
  // }, [emailKeywordState]);

  const labelStyles = {
    display: "flex",
    // alignItems: "center",
    height: "30px",
    marginTop: "10px",
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStylesNew = {
    fontSize: 15,
    width: "320px",
    height: "5px",
    padding: "13px 14px"
  };

  const rowStyles = {
    marginTop: "2%",
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/study-settings/email-builder">
              Email Builder
            </Link>{" "}
            | <p className="study-edit-link">Edit Email Builder</p>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Edit Email Builder</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              height: "auto",
              width: "100%",
            }}
            autoComplete="off"
          >
            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Name {/* {errors.name?.message ? ( */}
                  <span className="error-highlight">*</span>
                  {/* ) : (
                    <></>
                  )} */}
                </p>
              </Col>
              <Col md={10}>
                <TextField
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStylesNew,
                  }}
                  {...register("name", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.name && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.name.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Notes {/* {errors.notes?.message ? ( */}
                  <span className="error-highlight">*</span>
                  {/* ) : (
                    <></>
                  )} */}
                </p>
              </Col>
              <Col md={10}>
                <TextField
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStylesNew,
                  }}
                  {...register("notes", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.notes && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.notes.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Blinded{" "}
                  {errors.isBlinded?.message ? (
                    <span className="error-highlight">*</span>
                  ) : (
                    <></>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <div className="createVisitCheckbox">
                  <Checkbox
                    checked={isBlindedState}
                    style={{ fontSize: "12px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isBlinded", {
                      onChange: (e) => {
                        setIsBlindedState(!isBlindedState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.isBlinded && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.isBlinded.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Site Specific{" "}
                  {errors.isSiteSpecific?.message ? (
                    <span className="error-highlight">*</span>
                  ) : (
                    <></>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <div className="createVisitCheckbox">
                  <Checkbox
                    checked={isSiteSpecificState}
                    style={{ fontSize: "12px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isSiteSpecific", {
                      onChange: (e) => {
                        setIsSiteSpecificState(!isSiteSpecificState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.isSiteSpecific && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.isSiteSpecific.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Send To Creator{" "}
                  {errors.sendToCreator?.message ? (
                    <span className="error-highlight">*</span>
                  ) : (
                    <></>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <div className="createVisitCheckbox">
                  <Checkbox
                    checked={sendToCreatorState}
                    style={{ fontSize: "12px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("sendToCreator", {
                      onChange: (e) => {
                        setSendToCreatorState(!sendToCreatorState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.sendToCreator && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.sendToCreator.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Display On Screen{" "}
                  {errors.displayOnScreen?.message ? (
                    <span className="error-highlight">*</span>
                  ) : (
                    <></>
                  )}
                </p>
              </Col>
              <Col md={10}>
                <div className="createVisitCheckbox">
                  <Checkbox
                    checked={displayOnScreenState}
                    style={{ fontSize: "12px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("displayOnScreen", {
                      onChange: (e) => {
                        setDisplayOnScreenState(!displayOnScreenState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.displayOnScreen && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.displayOnScreen.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Patient Status TypeId{" "}
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={10}>
                <FormControl sx={{ width: "350px" }}>
                  <Select
                    value={patientTypeValue}
                    name="patientStatusTypeId"
                    inputProps={{
                      "aria-label": "Assigned Drug Kit",
                      className: "custom-select-email-class",
                    }}
                    InputProps={{
                      className: "custom-second-div-class",
                    }}
                    MenuProps={{
                      className: "custom-menu-root",
                    }}
                    defaultValue={""}
                    {...register("patientStatusTypeId", {
                      onChange: (e) => {
                        setValue("patientStatusTypeId", e.target.value, {
                          shouldValidate: true,
                        });
                        setPatientTypeValue(e.target.value);
                        setShowDialog(true);
                      },
                    })}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {patientStatusTypeOptions &&
                      patientStatusTypeOptions?.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.patientStatusDescription}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errors.patientStatusTypeId && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.patientStatusTypeId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row className="border-b-2 mb-4">
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Type <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={10}>
                <FormControl sx={{ width: "350px" }}>
                  <Select
                    value={emailTypeValue}
                    name="emailType"
                    inputProps={{
                      "aria-label": "Assigned Drug Kit",
                      className: "custom-select-email-class",
                    }}
                    InputProps={{
                      className: "custom-second-div-class",
                    }}
                    MenuProps={{
                      className: "custom-menu-root",
                    }}
                    defaultValue={""}
                    {...register("emailType", {
                      onChange: (e) => {
                        setValue("emailType", e.target.value, {
                          shouldValidate: true,
                        });
                        setEmailTypeValue(e.target.value);
                        setShowDialog(true);
                      },
                    })}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {emailTypeOptions &&
                      emailTypeOptions?.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errors.emailType && errors.emailType.message && (
                  <div className="createCaregiverFlexEnd">
                    <div className="createCaregiverError">
                      <span className="error-text">
                        {errors.emailType.message}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <p className="descriptionLabel">
                  KeyWords
                  <span className="error-highlight">*</span>
                </p>
                <div className="relative w-full">
                  <FaSearch className="absolute left-3 top-[1.8rem] transform -translate-y-1/2 text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search keywords..."
                    className="w-full pl-10 p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div
                  className="max-w-sm border border-gray-300 shadow-lg p-4 max-h-[350px] overflow-y-auto"
                  style={{ scrollbarWidth: "thin" }}
                >
                  <div className="flex flex-col gap-4">
                    {allKeyWords
                      .filter((item) => {
                        if (!searchQuery) return true;
                        const plainText = new DOMParser()
                          .parseFromString(item.resourcE_VALUE, "text/html")
                          .body.textContent.toLowerCase();
                        return plainText.includes(searchQuery.toLowerCase());
                      })
                      .map((item) => {
                        if (item.resourcE_VALUE) {
                          const getPlainText = (html) => {
                            const doc = new DOMParser().parseFromString(
                              html,
                              "text/html"
                            );
                            return doc.body.textContent || "";
                          };

                          const plainText = getPlainText(item.resourcE_VALUE);

                          return (
                            <span
                              className="px-4 py-2 break-words bg-[#3e4cf43d] text-[13px] rounded-md transition-colors cursor-pointer"
                              onClick={() => addKeyword(plainText)}
                              key={item.id}
                            >
                              <span
                                className="removePadKeywords"
                                dangerouslySetInnerHTML={{
                                  __html: item.resourcE_VALUE,
                                }}
                              />
                            </span>
                          );
                        }
                      })}
                  </div>
                </div>
              </Col>
              <Col md={10}>
                <p className="descriptionLabel">
                  Email Subject
                  <span className="error-highlight">*</span>
                </p>
                <div className="">
                  <div
                    onClick={() => {
                      handleClick("emailSubject");
                      editorKeyWords.current?.editor?.focus();
                    }}
                  >
                    <JoditEditor
                      ref={editorKeyWords}
                      value={emailKeywordState}
                      onBlur={onKeywordChange}
                      // onChange={onKeywordChange}
                      style={{ height: "40px" }}
                      className="hideEditorTool compact-editor"
                    />
                  </div>
                  {errors.keywords && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.keywords.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="">
                  <p className="descriptionLabel">
                    Email Editor
                    <span className="error-highlight">*</span>
                  </p>
                  <div
                    onClick={() => {
                      handleClick("emailEditor");
                      editorRef.current?.editor?.focus();
                    }}
                  >
                    <JoditEditor
                      ref={editorRef}
                      value={editorValue}
                      onBlur={onChange}
                    />
                  </div>
                  {errors.emailBody && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.emailBody.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={8}></Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                    className="study-management-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="submit"
                    className="study-management-create-btn-md"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
      )}
    </>
  );
};

export default EditEmail;
