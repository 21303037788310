import { studyServer } from "../../../../services/study_axios";

export const getAllQuestionnaireDataSevice = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/GetAllQuestionnaireData`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllQuestionnaireDropdownsDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Questionnaire/LoadingDataOnCreate")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getBusinessRulesDropdownService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Questionnaire/GetAllBusinessRuleOnLoad")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createQuestionnaireService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/Questionnaire/PostQuestionnaire", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getQuestionnaireById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/LoadingDataOnUpdate?questionnaireId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getQuestionnaireByIdSplitOneService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/LoadingDataOnUpdateSplit1?questionnaireId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getQuestionnaireByIdSplitTwoService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/LoadingDataOnUpdateSplit2?questionnaireId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editQuestionnaireService = (data, id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Questionnaire/PutQuestionnaire?Id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const copyQuestionnaireService = (copyId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/CopyQuestionnaire?QuestionnaireId=${copyId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteQuestionnaireService = (deleteId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/DeleteQuestionnaire?QuestionnaireId=${deleteId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getLanguagesServices = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Questionnaire/GetStudyLanguages`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getActiveLanguagesServices = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/LanguageDragDrop/GetActiveLanguages`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getEditorTranslationsServices = (QuestionId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`TranslationsByQuestionId?QuestionId=${QuestionId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllManageChoiceDataSevice = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Choice/GetChoicesByQuestion?QuestionId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const createChoiceService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/Choice/CreateChoice", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateChoiceService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put("/app/Choice/UpdateChoice", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};



export const getChoiceByIdDataService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Choice/GetChoiceById?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteChoiceService = (deleteId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Choice/DeleteChoice?Id=${deleteId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const createEditTransaltionService = (data, fieldId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`AddUpdateTranslations?QuestionId=${fieldId}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};